import { memo } from 'react';
import { Text } from 'hoi-poi-ui';

const DashboardWidgetRow = memo(({ leftColumn, title, subtitle, onClick, children }) => {
    return (
        <div className="fm-dashboard-widget__row" onClick={onClick}>
            <div className="fm-dashboard-widget__row-inner">
                {leftColumn}
                <div className="fm-dashboard-widget__row-content">
                    {!children && (
                        <>
                            <Text isTruncated useTooltip>
                                {title}
                            </Text>
                            <Text type="caption" color="neutral700" isTruncated useTooltip>
                                {subtitle}
                            </Text>
                        </>
                    )}
                    {children}
                </div>
            </div>
        </div>
    );
});

export default DashboardWidgetRow;
