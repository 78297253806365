import { memo, useCallback, useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { Button, Text, Link } from 'hoi-poi-ui';
import { EntityListActions } from 'actions';

import Context from 'managers/Context';
import { OPPORTUNITIES } from 'constants/Entities';
import { EntityModalActions } from 'actions';

import { formatDate, getDateMomentFromBackendTimestamp } from 'utils/dates';
import { getLiteral } from 'utils/getLiteral';
import { getRoute } from 'utils/routes';
import { logEvent } from 'utils/tracking';
import { getBackendBoolean } from 'utils/fm';
import UtilFormat from 'utils/UtilFormat';
import { getNumberAsCurrency } from 'utils/currency';
import { getUserSfmUrl } from 'utils/getUrl';

import { subscribe } from 'lib/EventBuser';
import { REFRESH_TABLE } from 'lib/events';

import useEntityDetail from 'containers/components/EntityDetail/hooks/useEntityDetail';
import DashboardWidget from '../components/DashboardWidget';
import DashboardWidgetRow from '../components/DashboardWidgetRow';
import OpportunityIcon from 'containers/opportunities/components/icons/OpportunityIcon';

const EMPTY_SVG = (
    <svg xmlns="http://www.w3.org/2000/svg" width={113} height={62} fill="none">
        <path
            fill="#D4D8DC"
            d="M17.607 87.575c8.961 0 16.226-.557 16.226-1.244 0-.688-7.265-1.245-16.226-1.245-8.962 0-16.226.557-16.226 1.245 0 .687 7.264 1.244 16.226 1.244Z"
        />
        <path
            fill="#FFC5AC"
            d="m22.862 31.734-6.473-1.776c1.728-1.64 2.48-3.185 2.256-4.636h4.217v6.412Z"
        />
        <path
            fill="#37526C"
            d="m23.549 81.648-2.091.296-2.52-23.28-4.02 23.181-2.158.099V52.548h13.339l-2.55 29.1Z"
        />
        <path
            fill="#37526C"
            d="M13.846 86.341c.328.01.65-.092.914-.288a1.48 1.48 0 0 0 .544-.793l.153-2.292-.308-1.626c-.812-.671-1.633-.657-2.463 0l-.264 3.405a1.484 1.484 0 0 0 .848 1.458c.18.084.377.13.576.136ZM22.477 86.341c.328.01.65-.092.915-.288a1.48 1.48 0 0 0 .544-.793l.152-2.292-.308-1.626c-.812-.671-1.633-.657-2.463 0l-.264 3.405a1.488 1.488 0 0 0 .372 1.104 1.472 1.472 0 0 0 1.052.49Z"
        />
        <path
            fill="#FFC5AC"
            d="M21.98 28.38c2.058 0 3.727-1.678 3.727-3.749 0-2.07-1.67-3.748-3.727-3.748-2.059 0-3.728 1.678-3.728 3.748 0 2.07 1.67 3.749 3.728 3.749Z"
        />
        <path
            fill="#E6E6E6"
            d="M26.926 53.025H12.705c1.843-8.591 1.838-15.511.37-21.107a1.503 1.503 0 0 1 .113-1.063 1.49 1.49 0 0 1 .79-.715l3.827-1.48c1.148 1.492 2.77 2.144 5.296 1.184l2.005.413c.488.1.924.375 1.226.774.302.398.451.893.418 1.393-.147 2.189-.09 4.394-.216 6.298-.393 5.895-.563 10.491.392 14.303Z"
        />
        <path
            fill="#37526C"
            d="M20.018 19.7s2.158-2.467 4.708-.1l.686.198-.49.394s2.55 1.184 2.354 3.65c0 0-.392-.098-.589 0-.196.099-.294 1.184-.294 1.184s-.294-.79-.392-.888c-.098-.099-1.471 1.085-3.727-.69 0 0-.687 1.775-1.471 1.578-.785-.197-1.864-.099-2.354 1.184 0 0-2.649-5.722 1.569-6.51Z"
        />
        <path
            fill="#FFC5AC"
            d="m27.374 50.87-1.26 1.698a1.669 1.669 0 0 0 .358 2.343 1.648 1.648 0 0 0 2.601-1.086l.36-2.264.687-9.766-.49-4.932-3.139 1.184.883 4.242v8.582Z"
        />
        <path
            fill="#E6E6E6"
            d="m26.099 39.23 3.825-1.775-.196-1.398a5.76 5.76 0 0 0-.998-3.242 5.711 5.711 0 0 0-2.645-2.107l-1.163-.454 1.177 8.976Z"
        />
        <path
            fill="#FFC5AC"
            d="m12.171 50.476 1.26 1.697a1.669 1.669 0 0 1-.357 2.343 1.647 1.647 0 0 1-2.267-.317 1.668 1.668 0 0 1-.335-.768l-.36-2.264-.687-9.766.49-4.932 3.14 1.184-.883 4.241v8.582Z"
        />
        <path
            fill="#E6E6E6"
            d="M13.446 38.836 9.621 37.06l.196-1.397c0-1.157.348-2.287.998-3.242a5.71 5.71 0 0 1 2.645-2.108l1.163-.454-1.177 8.977ZM113.588 76.58H48.134a1.03 1.03 0 0 1-.73-.304 1.042 1.042 0 0 1-.302-.733V46.915c0-.275.11-.538.303-.733a1.03 1.03 0 0 1 .729-.304h65.454a1.031 1.031 0 0 1 1.031 1.037v28.628a1.031 1.031 0 0 1-1.031 1.037ZM48.134 46.293a.618.618 0 0 0-.619.622v28.628a.625.625 0 0 0 .619.622h65.454a.617.617 0 0 0 .437-.182.63.63 0 0 0 .182-.44V46.915a.63.63 0 0 0-.182-.44.617.617 0 0 0-.437-.182H48.134Z"
        />
        <path
            fill="#E6E6E6"
            d="M50.815 49.404a.617.617 0 0 0-.619.623.624.624 0 0 0 .62.622h8.662a.617.617 0 0 0 .619-.622.624.624 0 0 0-.619-.623h-8.663ZM110.121 53.733v11.54H51.703v-6.076c.405-.261.812-.521 1.222-.773 1.754-1.079 3.631-2.088 5.677-2.463a8.525 8.525 0 0 1 2.93-.036c1.027.17 2.01.526 2.983.883.96.353 1.92.717 2.924.922a8.516 8.516 0 0 0 2.966.06c1.879-.284 3.644-1.044 5.4-1.73 1.77-.691 3.592-1.343 5.505-1.43 1.89-.085 3.756.364 5.549.92 3.655 1.135 7.388 2.818 11.258 1.664 3.931-1.17 7.546-4.548 11.901-3.546a.166.166 0 0 1 .103.065Z"
        />
        <path
            fill="#FF8C00"
            d="M110.121 53.934a.174.174 0 0 1-.2.088 6.477 6.477 0 0 0-1.583-.164c-4.284.076-7.578 3.545-11.767 4.064-3.778.467-7.332-1.38-10.884-2.346-1.845-.501-3.748-.767-5.647-.426-1.884.337-3.652 1.112-5.428 1.8-1.71.662-3.487 1.278-5.338 1.309-2.035.034-3.868-.81-5.756-1.452-.982-.335-1.99-.6-3.03-.626a9.117 9.117 0 0 0-2.985.465c-2.007.641-3.814 1.785-5.574 2.92a.155.155 0 0 1-.104.029.188.188 0 0 1-.158-.145.191.191 0 0 1 .078-.201l.08-.052c.404-.261.81-.521 1.22-.773 1.75-1.079 3.623-2.088 5.665-2.462a8.484 8.484 0 0 1 2.925-.037c1.024.17 2.005.526 2.975.883.958.353 1.918.717 2.919.922a8.48 8.48 0 0 0 2.96.06c1.874-.284 3.635-1.044 5.389-1.73 1.765-.691 3.583-1.343 5.493-1.43 1.885-.085 3.748.364 5.537.92 3.648 1.135 7.372 2.818 11.235 1.664 3.922-1.17 7.529-4.548 11.876-3.546a.162.162 0 0 1 .102.065.189.189 0 0 1 0 .2Z"
        />
        <path
            fill="#FF8C00"
            d="M59.753 56.933a.856.856 0 0 0 .853-.859.856.856 0 0 0-.853-.858.856.856 0 0 0-.854.858c0 .475.382.859.854.859ZM81.34 55.828a.856.856 0 0 0 .853-.858.856.856 0 0 0-.854-.859.856.856 0 0 0-.854.859c0 .474.383.858.854.858ZM107.682 54.725a.857.857 0 0 0 .854-.859.857.857 0 0 0-.854-.858.856.856 0 0 0-.854.858c0 .475.382.859.854.859Z"
        />
        <path
            fill="#E6E6E6"
            d="M59.272 70.15a.617.617 0 0 0-.62.622.624.624 0 0 0 .62.622h8.663a.617.617 0 0 0 .618-.622.624.624 0 0 0-.618-.623h-8.663Z"
        />
        <path
            fill="#98D5F1"
            d="M53.703 73.054a2.344 2.344 0 0 0 2.337-2.351 2.344 2.344 0 0 0-2.337-2.351 2.344 2.344 0 0 0-2.338 2.35 2.344 2.344 0 0 0 2.338 2.352Z"
        />
        <path
            fill="#E6E6E6"
            d="M80.173 70.15a.617.617 0 0 0-.619.622.624.624 0 0 0 .619.622h8.663a.617.617 0 0 0 .619-.622.624.624 0 0 0-.619-.623h-8.663Z"
        />
        <path
            fill="#98D5F1"
            d="M74.604 73.054a2.344 2.344 0 0 0 2.338-2.351 2.344 2.344 0 0 0-2.338-2.351 2.344 2.344 0 0 0-2.337 2.35 2.344 2.344 0 0 0 2.337 2.352Z"
        />
        <path
            fill="#E6E6E6"
            d="M101.074 70.15a.624.624 0 0 0 0 1.245h8.663a.618.618 0 0 0 .619-.623.624.624 0 0 0-.619-.623h-8.663Z"
        />
        <path
            fill="#98D5F1"
            d="M95.505 73.054a2.344 2.344 0 0 0 2.338-2.351 2.344 2.344 0 0 0-2.338-2.351 2.344 2.344 0 0 0-2.337 2.35 2.344 2.344 0 0 0 2.337 2.352Z"
        />
        <path
            fill="#E6E6E6"
            d="M109.107 38.148H76.815c-.63 0-1.14-.366-1.14-.816v-7.059c0-.45.51-.816 1.14-.816h32.292c.63 0 1.14.366 1.141.816v7.059c-.001.45-.511.815-1.141.816Zm-32.292-8.365c-.378 0-.684.22-.684.49v7.059c0 .27.306.489.684.49h32.292c.378 0 .684-.22.685-.49v-7.059c0-.27-.307-.49-.685-.49H76.815Z"
        />
        <path
            fill="#FF8C00"
            d="M84.117 33.469a.686.686 0 0 0-.688.691V39a.694.694 0 0 0 .688.692h24.614a.687.687 0 0 0 .687-.691v-4.84a.692.692 0 0 0-.687-.692H84.117Z"
        />
        <path
            fill="#fff"
            d="M87.555 38.172c.835 0 1.512-.682 1.512-1.522s-.677-1.521-1.512-1.521c-.836 0-1.513.681-1.513 1.521 0 .84.677 1.522 1.513 1.522ZM94.448 35.99a.617.617 0 0 0-.619.622.624.624 0 0 0 .619.622h8.663a.62.62 0 0 0 .437-1.062.616.616 0 0 0-.437-.183h-8.663Z"
        />
        <path
            fill="#E6E6E6"
            d="M84.906 21.691H52.614c-.63 0-1.14-.366-1.14-.816v-7.059c0-.45.51-.816 1.14-.816h32.292c.63 0 1.14.366 1.14.816v7.059c0 .45-.51.815-1.14.816Zm-32.292-8.365c-.378 0-.684.22-.684.49v7.059c0 .27.306.489.684.49h32.292c.378 0 .684-.22.684-.49v-7.059c0-.27-.306-.49-.684-.49H52.614Z"
        />
        <path
            fill="#FF8C00"
            d="M59.915 17.012a.695.695 0 0 0-.688.691v4.84a.695.695 0 0 0 .688.692H84.53a.687.687 0 0 0 .688-.691v-4.84a.694.694 0 0 0-.688-.692H59.915Z"
        />
        <path
            fill="#fff"
            d="M63.353 21.713c.835 0 1.512-.68 1.512-1.52 0-.841-.677-1.522-1.512-1.522-.836 0-1.513.681-1.513 1.521 0 .84.677 1.521 1.513 1.521ZM70.247 19.532a.617.617 0 0 0-.62.623.624.624 0 0 0 .62.622h8.663a.617.617 0 0 0 .618-.622.624.624 0 0 0-.618-.623h-8.663Z"
        />
    </svg>
);

const OpportunitiesDashboardWidget = memo(() => {
    const { openTab } = useEntityDetail();
    const dispatch = useDispatch();
    const canCreate = useSelector(
        (state) => state?.config?.permission?.crud_permission?.[OPPORTUNITIES.permission]?.create,
    );
    const isOpportunitiesEnabled = useSelector((state) => state?.config?.permission?.expedientes);
    const [loading, setLoading] = useState(isOpportunitiesEnabled);
    const [error, setError] = useState();
    const [list, setList] = useState([]);
    const [filter, setFilter] = useState();
    const [filterOptions, setFilterOptions] = useState([]);

    const getList = useCallback(() => {
        setLoading(true);
        setError(false);

        let filters = {
            dtprevfechaventa: {
                id: 'dtprevfechaventa',
                dataType: 'date',
                value: {
                    from: moment().startOf('day'),
                    to: '',
                },
                asExtra: true,
            },
        };

        if (filter?.length) {
            filters.state = {
                id: 'state',
                dataType: 'singleValueList',
                value: filter?.map((state) => state.value) || [],
            };
        }

        Context.domainManager.getEntityList(
            OPPORTUNITIES.entity,
            0,
            100,
            filters,
            'expectedclose',
            '0',
            null,
            true,
            (result) => {
                setLoading(false);
                if (!result.Success) {
                    return setError(true);
                }
                setList(result.Data || []);
            },
            (error) => {
                setLoading(false);
                console.error(error);
                if (error === 'No result') {
                    setList([]);
                } else {
                    setError(true);
                }
            },
        );
    }, [filter]);

    useEffect(() => {
        if (!isOpportunitiesEnabled) return;
        dispatch(EntityListActions.setUseLazyLoad(OPPORTUNITIES, true));
        return subscribe(`${REFRESH_TABLE}_${OPPORTUNITIES.entity}`, () => {
            getList();
        });
    }, [dispatch, getList, isOpportunitiesEnabled]);

    useEffect(() => {
        if (!isOpportunitiesEnabled) return;
        getList();
        Context.serverListManager
            .getList('tblEstadoExpediente')
            .then(setFilterOptions)
            .catch((e) => console.error(e));
    }, [getList, isOpportunitiesEnabled]);

    const onViewMore = useCallback(() => {
        logEvent({
            event: 'dashboard',
            submodule: OPPORTUNITIES.trueName,
            functionality: 'list',
        });
        window.open(getRoute(OPPORTUNITIES.route), '_blank');
    }, []);

    const onAdd = useCallback(() => {
        dispatch(
            EntityModalActions.init({
                entity: OPPORTUNITIES,
                data: {},
                labels: {
                    title: getLiteral('page_title_opportunities_create'),
                    success: getLiteral('succes_entitycreatedsuccessfully'),
                    error: getLiteral('label_failed_insert_opportunity_salesforce'),
                    deleteModalTitle: getLiteral('title_delete_opportunities'),
                },
                hideDelete: true,
            }),
        );

        logEvent({
            event: 'dashboard',
            submodule: OPPORTUNITIES.trueName,
            functionality: 'create',
        });
    }, [dispatch]);

    const onClick = useCallback(
        (activity) => {
            openTab({
                entity: OPPORTUNITIES,
                id: activity.Id,
                hasCrudInDetail: true,
                toTab: true,
                info: {},
                avoidRedirects: true,
            });

            logEvent({
                event: 'dashboard',
                submodule: OPPORTUNITIES.trueName,
                functionality: 'detailView',
            });
        },
        [openTab],
    );

    const onChangeFilter = useCallback((filter) => {
        setFilter(filter);
        logEvent({
            event: 'dashboard',
            submodule: OPPORTUNITIES.trueName,
            functionality: 'filter',
        });
    }, []);

    const getStateProbabilityText = useCallback((opportunity) => {
        let stateProbabilityText;
        if (opportunity.OpportunityType && opportunity.Probability) {
            stateProbabilityText = `${opportunity.OpportunityType} · ${opportunity.Probability * 10}%`;
        } else if (opportunity.OpportunityType && !opportunity.Probability) {
            stateProbabilityText = `${opportunity.OpportunityType}`;
        } else if (!opportunity.OpportunityType && opportunity.Probability) {
            stateProbabilityText = `${opportunity.Probability * 10}%`;
        } else {
            stateProbabilityText = `-`;
        }
        return stateProbabilityText;
    }, []);

    const getDate = useCallback((opportunity) => {
        const isWin = opportunity.IsWin === '1';
        const isLost = opportunity.IsLost === '1';
        const isClosed = isWin || isLost;

        let date = '-';
        if (isClosed && opportunity.Close_timestamp && opportunity.Close_timestamp !== '0') {
            date = getDateMomentFromBackendTimestamp(opportunity.Close_timestamp);
        } else if (
            !isClosed &&
            opportunity.ExpectedClose_timestamp &&
            opportunity.ExpectedClose_timestamp !== '0'
        ) {
            date = getDateMomentFromBackendTimestamp(opportunity.ExpectedClose_timestamp);
        }
        if (date !== '-') {
            date = formatDate(date);
        }

        return date;
    }, []);

    const getStateClassName = useCallback((opportunity) => {
        const isWin = opportunity.IsWin === '1';
        const isLost = opportunity.IsLost === '1';
        const isClosed = isWin || isLost;

        let classNameStateProbability = '';
        if (isClosed) {
            if (isWin)
                classNameStateProbability = 'fm-opportunity-dashboard-widget__row__state--win';
            else if (isLost)
                classNameStateProbability = 'fm-opportunity-dashboard-widget__row__state--lost';
        }

        return classNameStateProbability;
    }, []);

    const openSupportChat = useCallback(
        () => window.Intercom && window.Intercom('showNewMessage'),
        [],
    );

    const emptyActions = useMemo(() => {
        let actions = [];
        if (!isOpportunitiesEnabled) {
            actions = [
                <Button
                    type="secondary"
                    size="small"
                    href={getLiteral('label_link_learn_more_opportunities')}
                    target="_blank"
                >
                    {getLiteral('label_learn_more')}
                </Button>,
                <Button type="secondary" size="small" onClick={openSupportChat}>
                    {getLiteral('action_support_chat')}
                </Button>,
            ];
        } else if (canCreate) {
            actions.push(
                <Button type="secondary" size="small" onClick={onAdd}>
                    {getLiteral('action_add_opportunity')}
                </Button>,
            );
        }
        return actions;
    }, [canCreate, isOpportunitiesEnabled, onAdd, openSupportChat]);

    const rowList = useMemo(
        () =>
            list?.map((i) => (
                <DashboardWidgetRow
                    item={i}
                    onClick={() => onClick(i)}
                    leftColumn={
                        <OpportunityIcon
                            won={getBackendBoolean(i.IsWin)}
                            lost={getBackendBoolean(i.IsLost)}
                            expirationDays={UtilFormat.getDifferentDay(i.ExpectedClose)}
                            className="fm-opportunity-dashboard-widget__icon"
                        />
                    }
                >
                    <div className="fm-opportunity-dashboard-widget__row">
                        <Text color="neutral900" isTruncated useTooltip>
                            {i.Reference || '-'}
                        </Text>
                        <Text type="subtitle" isTruncated useTooltip>
                            {i.Amount ? getNumberAsCurrency(i.Amount, i.SymbolCurrency) : '-'}
                        </Text>
                    </div>
                    <div className="fm-opportunity-dashboard-widget__row">
                        <Text color="neutral700" isTruncated useTooltip>
                            {i.TypeExpediente || '-'}
                        </Text>
                        <Text color="neutral700">{getDate(i)}</Text>
                    </div>
                    <div className="fm-opportunity-dashboard-widget__row">
                        <Text className={getStateClassName(i)} isTruncated useTooltip>
                            {getStateProbabilityText(i)}
                        </Text>
                        <Link
                            href={getUserSfmUrl(i.IdComercial)}
                            target="_blank"
                            isTruncated
                            useTooltip
                        >
                            {i.Comercial}
                        </Link>
                    </div>
                </DashboardWidgetRow>
            )),
        [getDate, getStateClassName, getStateProbabilityText, list, onClick],
    );

    const showList = !error && !loading && isOpportunitiesEnabled;

    return (
        <DashboardWidget
            title={getLiteral('title_opportunities')}
            // Actions
            onViewMore={onViewMore}
            onAdd={canCreate ? onAdd : undefined}
            addTooltip={getLiteral('action_add_opportunity')}
            // Filter
            filterLabel={getLiteral('label_opportunity_status')}
            filterOptions={filterOptions}
            onChangeFilter={onChangeFilter}
            filterIsMulti
            // State
            isLoading={loading}
            isEmpty={list?.length === 0 && !error}
            isError={error}
            // Empty view
            emptyImage={EMPTY_SVG}
            emptyTitle={getLiteral(
                isOpportunitiesEnabled
                    ? 'label_empty_screen_opportunities'
                    : 'label_empty_widget_opportunities_no_enabled',
            )}
            emptySubtitle={getLiteral(
                isOpportunitiesEnabled
                    ? 'label_empty_screen_opportunities_desc'
                    : 'label_empty_widget_opportunities_no_enabled_desc',
            )}
            emptyActions={emptyActions}
        >
            {showList && rowList}
        </DashboardWidget>
    );
});

export default OpportunitiesDashboardWidget;
