import { EntityCrudService } from 'services';
import { formatDateForServer } from 'utils/dates';
import { REG_EX_URL_ONLY } from 'constants/Constants';
import { isEmptyObject } from 'utils/objects';
import axios from 'axios';
import Context from 'managers/Context';
import { executeSandbox } from 'utils/sandbox';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';
import { errorToast } from 'utils/toast';
import { getBackendBoolean } from 'utils/fm';
import { subscribe } from 'lib/EventBuser';
import { UPDATE_FILES_TO_SAVE } from 'lib/events';
import { toDecimal, toBool, toNumber } from 'utils/crud';
import { FUZZY_ENTITIES } from 'utils/fuzzy';
import { getEntityValue } from 'services/Fuzzy';

const defaultInputSchema = {
    mandatory: false,
    readOnly: false,
    dynamicHidden: false,
    defaultValue: '',
};

let customValidations = {};
let cueOfFiles = {};

subscribe(UPDATE_FILES_TO_SAVE, ({ entity, files }) => {
    cueOfFiles[entity] = files;
});

export default class EntityCrudManager {
    init({
        entity,
        id,
        isBulkAction,
        isFromDetail = false,
        data,
        isModal,
        crudTab,
        extraInfo,
        version,
    }) {
        const manager = this.context.entityManager.getEntitiesManager(entity);
        const model = this.context.entityManager.getModel(entity);
        const isEdit = !!id;

        const getEntity =
            manager?.hasCustomCrud && manager.hasCustomCrud(extraInfo)
                ? manager.customInitCrud
                : this.getEntity;

        return new Promise((resolve, reject) => {
            Promise.all([
                this.getSchema(entity, !id, isBulkAction, crudTab),
                this.getEntityDefaults(manager, !id),
                getEntity(entity, id, isFromDetail, data, this.afterGetEntity),
                this.beforeCrud(manager, id),
                this.getCustomValidations(entity),
                this.getCrudPreComponents(entity),
            ])
                .then(
                    ([
                        { schema, dependencyMap, dynamicMap },
                        defaults,
                        entityData,
                        beforeData = {},
                        customValidations,
                        preComponents = [],
                    ]) => {
                        cueOfFiles = {};
                        if (
                            isEdit &&
                            manager.checkIfIsValidEntity &&
                            !manager.checkIfIsValidEntity(entityData, id)
                        ) {
                            console.error('invalid data');
                            reject();
                            return;
                        }

                        if (manager.prepareDataForCrud) {
                            entityData = manager.prepareDataForCrud(entityData);
                        }

                        let finalData = {
                            ...defaults,
                            ...entityData,
                            ...beforeData,
                        };

                        if (isBulkAction) {
                            finalData = {};
                        } else {
                            // TODO check if we setup all the data of an edit in the same way of getDbDefaultValues
                            if (!id) {
                                // is a creation
                                finalData = this.getDbDefaultValues(finalData, schema);
                            }
                            if (manager && manager.getSpecificEntityDefaultValues) {
                                finalData = manager.getSpecificEntityDefaultValues(finalData, id);
                            }

                            // Prepoulated data for creation
                            if (!isFromDetail && data)
                                finalData = {
                                    ...finalData,
                                    ...data,
                                };
                        }

                        if (manager && manager.dataAffectsSchema) {
                            // Here finalData is NOT a model
                            schema = manager.dataAffectsSchema({
                                data: finalData,
                                schema,
                                isModal,
                                entity,
                            });
                        }

                        // Mapped structure for fast access
                        const mappedSchema = this.createMappedSchema(schema);

                        // Mapped config map for fast access
                        const configMap = this.createConfigMap(schema);

                        // Format from backend
                        if (id && finalData && model && model.toCrud) {
                            if (model.toCrudFromCustomInit && manager?.hasCustomCrud) {
                                finalData = manager.hasCustomCrud(extraInfo)
                                    ? finalData
                                    : model.toCrud({ data: finalData, mappedSchema, version }) ||
                                      {};
                            } else {
                                finalData =
                                    model.toCrud({ data: finalData, mappedSchema, version }) || {};
                            }
                        }

                        if (isEdit) {
                            resolve({
                                schema,
                                mappedSchema,
                                configMap,
                                dependencyMap,
                                dynamicMap,
                                data: finalData,
                                preComponents,
                            });
                        } else {
                            // Getting data from query string
                            this.getQueryStringData({
                                schema,
                                mappedSchema,
                                configMap,
                            })
                                .then((queryStringData = {}) => {
                                    resolve({
                                        schema,
                                        mappedSchema,
                                        configMap,
                                        dependencyMap,
                                        dynamicMap,
                                        data: { ...finalData, ...queryStringData },
                                        preComponents,
                                    });
                                })
                                .catch(reject);
                        }
                    },
                )
                .catch(reject);
        });
    }

    getEntity(entity, id, isFromDetail, data, afterGetEntity) {
        if (!entity || !id) return Promise.resolve();
        return new Promise((resolve, reject) => {
            const manager = Context.entityManager.getEntitiesManager(entity);
            if (isFromDetail && data) return resolve(data);
            if (!entity.useNewApi) {
                manager.getEntity(
                    id,
                    (data) => {
                        if (afterGetEntity)
                            afterGetEntity(manager, data).then(resolve).catch(reject);
                        else resolve(data);
                    },
                    (error) => {
                        console.error(error);
                        reject(error);
                    },
                    true,
                );
            } else {
                // if we need to customize the default getEntity, add the default
                // parameters in the variable and customize what you want for the service
                const parameters =
                    manager && manager.calculateGetEntityParameters
                        ? manager.calculateGetEntityParameters(id)
                        : null;
                EntityCrudService.get(entity, id, parameters)
                    .then((result) => {
                        afterGetEntity(manager, result).then(resolve).catch(reject);
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error);
                    });
            }
        });
    }

    afterGetEntity(manager, data) {
        if (manager && manager.afterDetail) return manager.afterGetEntity(data);
        return Promise.resolve(data);
    }

    getEntityDefaults(manager) {
        if (manager && manager.getDefaults) return manager.getDefaults();
        Promise.resolve({});
    }

    beforeCrud(manager, id) {
        if (manager && manager.beforeCrud) return manager.beforeCrud(id);
        return Promise.resolve({});
    }

    beforeSave(manager, schema, data, isDuplicated) {
        if (manager && manager.beforeSave) return manager.beforeSave(schema, data, isDuplicated);
        return data;
    }

    beforeDelete(manager, schema, data) {
        if (manager && manager.beforeDelete) return manager.beforeDelete(schema, data);
        return data;
    }

    manageResultSave(manager, schema, serverData, result) {
        if (manager && manager.manageResultSave)
            return manager.manageResultSave(schema, serverData, result);
        return result;
    }

    afterSave({ manager, schema, data, result, entity, isModal, originalData }) {
        if (manager && manager.afterSave)
            return manager.afterSave({ schema, data, result, entity, isModal, originalData });
        return Promise.resolve(result);
    }

    afterDelete({ manager, schema, data, isModal }) {
        if (manager && manager.afterDelete) return manager.afterDelete({ schema, data, isModal });
        return Promise.resolve(data);
    }

    afterGetSchema(manager, obj, defaultInputSchema) {
        if (manager && manager.afterGetSchema)
            return manager.afterGetSchema(obj, defaultInputSchema);
        return Promise.resolve(obj);
    }

    filterSchemaForBulk(schema) {
        return schema
            .filter((tab) => !tab.hiddenForBulk)
            .reduce((obj, tab) => {
                const newFields = tab.fields
                    ? tab.fields.filter((field) => !field.hiddenForBulk)
                    : [];
                if (newFields && newFields.length) obj.push({ ...tab, fields: newFields });
                return obj;
            }, []);
    }

    getSchema(entity, isCreate, isBulkAction, crudTab) {
        const entityManager = this.context.entityManager.getEntitiesManager(entity);

        // Don't show extrafields in activities phonecall & email CRUDs
        const skipExtraFieldsInCrud = entityManager.hasCustomCrud && entityManager.hasCustomCrud();

        return new Promise((resolve, reject) => {
            Promise.all([
                entityManager.getSchema(defaultInputSchema, crudTab),
                this.context.extraFieldManager.getExtraFieldsCrudSchema(
                    entity,
                    defaultInputSchema,
                    skipExtraFieldsInCrud,
                ),
                this.moreFieldsSchema(entityManager, defaultInputSchema, isCreate),
            ])
                .then(([schema, extraFieldsSchema, moreFieldsSchema]) => {
                    schema = this.standardFieldsConfiguration(
                        entityManager,
                        schema,
                        entity,
                        isCreate,
                    );

                    if (moreFieldsSchema.length) {
                        moreFieldsSchema = this.standardFieldsConfiguration(
                            entityManager,
                            moreFieldsSchema,
                            entity,
                            isCreate,
                        );
                    }

                    const { extraSchema } = this.extraFieldsConfiguration(
                        extraFieldsSchema,
                        isCreate,
                    );

                    schema = [...schema, ...moreFieldsSchema, ...extraSchema];
                    if (isBulkAction) schema = this.filterSchemaForBulk(schema);
                    schema = this.injectListFeatures(schema, entity);
                    const { dependencyMap, dynamicMap } =
                        this.dynamicAndDependenceConfiguration(schema);

                    this.afterGetSchema(
                        entityManager,
                        {
                            schema,
                            dependencyMap,
                            dynamicMap,
                        },
                        defaultInputSchema,
                    )
                        .then(resolve)
                        .catch(reject);
                })
                .catch(reject);
        });
    }

    createMappedSchema(schema) {
        const mappedSchema = {};
        schema.forEach((tab) => {
            tab.fields.forEach((field) => {
                if (field.inputs && field.inputs.length > 0) {
                    field.inputs.forEach((input) => (mappedSchema[input.id] = input));
                } else {
                    mappedSchema[field.id] = field;
                }
            });
        });
        return mappedSchema;
    }

    createConfigMap(schema) {
        const configMap = {};
        schema.forEach((tab) => {
            tab.fields.forEach((field) => {
                if (field.inputs && field.inputs.length > 0) {
                    field.inputs.forEach(
                        (input) => (configMap[input.fieldConfiguration] = input.id),
                    );
                } else {
                    configMap[field.fieldConfiguration] = field.id;
                }
            });
        });
        return configMap;
    }

    injectListFeatures(schema, entity) {
        schema.forEach((tab) => {
            tab.fields.forEach((field) => {
                if (field?.inputAttrs?.list && !field?.inputAttrs?.feature) {
                    field.inputAttrs.feature = entity.entity;
                } else if (field.inputs) {
                    field.inputs.forEach((input) => {
                        if (input?.inputAttrs?.list && !input?.inputAttrs?.feature) {
                            input.inputAttrs.feature = entity.entity;
                        }
                    });
                }
            });
        });
        return schema;
    }

    standardFieldsConfiguration(manager, schema, entity, isCreate) {
        const config = this.context.cacheManager.getConfigStore();
        if (!config) return schema;

        let dbSchema = config?.standardFieldsSchemaMap?.[entity.extraFieldName] || {};
        let specialHiddenFields = [];
        if (manager && manager.calculateSpecialHiddenFields) {
            specialHiddenFields = manager.calculateSpecialHiddenFields(dbSchema);
        }

        for (const tab of schema) {
            for (let field of tab.fields) {
                const specialFieldTypes = ['multipleField', 'multipleFuzzySearchSingle'];
                if (specialFieldTypes.includes(field.type) && field.hasOwnProperty('inputs')) {
                    for (let insideField of field.inputs) {
                        insideField = this.defineStandardFieldConfiguration(
                            insideField,
                            dbSchema,
                            specialHiddenFields,
                            isCreate,
                        );
                    }
                } else {
                    field = this.defineStandardFieldConfiguration(
                        field,
                        dbSchema,
                        specialHiddenFields,
                        isCreate,
                    );
                }
            }
        }

        return schema;
    }

    defineStandardFieldConfiguration(field, dbSchema, specialHiddenFields, isCreate) {
        let idField = field.fieldConfiguration;
        if (!idField) {
            idField = field.id;
        }

        const fieldSchema = dbSchema?.[idField.toLowerCase()] || dbSchema?.[idField];

        if (fieldSchema) {
            field.mandatory = fieldSchema.mandatory;
            field.defaultValue = fieldSchema.defaultValue || field.defaultValue;
            field.readOnly = fieldSchema.readOnly;
            field.hidden = fieldSchema.isHidden;
            if (fieldSchema.hasOwnProperty('dataLength') && fieldSchema !== '-1') {
                field.dataLength = parseInt(fieldSchema.dataLength, 10);
            }

            if (fieldSchema.hasOwnProperty('strParentField') && fieldSchema.strParentField) {
                if (!field.inputAttrs) {
                    field['inputAttrs'] = {};
                }
                field.inputAttrs['parentField'] = fieldSchema.strParentField;
            }

            if (fieldSchema.hasOwnProperty('TiposEntity') && fieldSchema.TiposEntity) {
                field.dynamicFrom = fieldSchema.TiposEntity;
                field.dynamicFromIds = fieldSchema.strTiposEntity.split(';');
            }
        }

        if (specialHiddenFields.includes(idField)) {
            field.hidden = true;
        }

        if (isCreate && field.mandatory && field.readOnly) {
            field.readOnly = false;
        }

        if (fieldSchema && field.type === 'text') {
            field.checkDuplicates = getBackendBoolean(fieldSchema.checkDuplicates);
            field.checkDuplicateId = fieldSchema.id;
        }
    }

    extraFieldsConfiguration(extraSchema, isCreate) {
        for (const tab of extraSchema) {
            for (const field of tab.fields) {
                if (isCreate && field.mandatory && field.readOnly) {
                    field.readOnly = false;
                }

                field.isExtra = true;

                // Force textarea for text fields
                if (field.type === 'text') {
                    field.type = 'textarea';
                    field.inputAttrs = {
                        ...(field.inputAttrs || {}),
                        multiLine: true,
                        withoutResizer: true,
                        rows: 0,
                        rowsMax: 15,
                    };
                    field.checkDuplicates = getBackendBoolean(field.checkDuplicates);
                    field.checkDuplicateId = field?.id;
                }

                if (['singleValueList', 'multipleValueList'].includes(field.type)) {
                    if (!field.inputAttrs) field.inputAttrs = {};
                    field.inputAttrs.withNoOptionValue = true;
                }
            }

            const visibleFields = tab.fields.filter((field) => !field.hidden);
            tab.show = visibleFields.length > 0;
        }
        return {
            extraSchema,
        };
    }

    moreFieldsSchema(manager, defaultInputSchema, isCreate) {
        if (!(manager && manager.moreFieldsSchema)) return Promise.resolve([]);
        return manager.moreFieldsSchema(defaultInputSchema, isCreate);
    }

    dynamicAndDependenceConfiguration(schema) {
        const dependencyMap = {};
        const dynamicMap = {};

        for (const tab of schema) {
            for (const field of tab.fields) {
                if (field.hasOwnProperty('inputAttrs') && field.inputAttrs.parentField) {
                    const parentField = field.inputAttrs.parentField.toLowerCase();
                    if (!dependencyMap[parentField]) {
                        dependencyMap[parentField] = [];
                    }
                    if (!field.inputs) {
                        dependencyMap[parentField].push(field.id);
                    } else {
                        field.inputs.forEach((current) => {
                            dependencyMap[parentField].push(current.id);
                        });
                    }
                }

                if (field.dynamicFrom) {
                    const dynamicFrom = field.dynamicFrom;
                    if (!dynamicMap[dynamicFrom]) {
                        dynamicMap[dynamicFrom] = {};
                    }
                    dynamicMap[dynamicFrom][field.id] = field.dynamicFromIds;
                }
            }
        }
        return {
            dependencyMap,
            dynamicMap,
        };
    }

    getDbDefaultValues(data, schema) {
        schema.forEach((tab) => {
            tab.fields.forEach((field) => {
                if (field.hasOwnProperty('defaultValue') && field.defaultValue !== '') {
                    data = this.getDbDefaultValuesField(data, field);
                } else if (field.hasOwnProperty('inputs')) {
                    field.inputs.forEach((fieldInside) => {
                        data = this.getDbDefaultValuesField(data, fieldInside);
                    });
                }
            });
        });
        return data;
    }

    getDbDefaultValuesField(data, field) {
        if (!field.defaultValue && field.defaultValue !== '') return data;

        switch (field.type) {
            case 'multipleValueList':
                data[field.id] =
                    field.defaultValue && Array.isArray(field.defaultValue)
                        ? field.defaultValue
                        : field.defaultValue.split(';');
                break;
            case 'fuzzySearchMultiple':
                console.warn(
                    `[CRUD] Can't load the default value for this because the label is not provided`,
                    field.type,
                );
                break;
            default:
                data[field.id] = field.defaultValue;
                break;
        }
        return data;
    }

    duplicate(entity, data) {
        return new Promise((resolve, reject) => {
            let { id, Id, ...newData } = data;
            const manager = this.context.entityManager.getEntitiesManager(entity);
            if (manager.processDuplicatedData) newData = manager.processDuplicatedData(newData);
            newData.readOnly = false;
            this.context.domainManager.createEntity(
                entity.entity,
                newData,
                (result) => {
                    if (result?.Status?.code === 'DuplicatedEntity') resolve(result?.Status?.code);

                    const duplicatedInfo = { id: result.Insert?.id || result.Insert?.[0]?.id };
                    resolve(duplicatedInfo);
                },
                (error) => {
                    console.error(error);
                    reject({
                        serverError: error.error || true,
                    });
                },
            );
        });
    }

    finishSave({
        data,
        hasFilesToSave,
        saveFilesPostSave,
        result,
        resolve,
        entityManager,
        schema,
        serverData,
        entity,
        isModal,
        originalData,
    }) {
        const id = data?.id || result?.Insert?.id || result?.id || '';
        if (hasFilesToSave && saveFilesPostSave && id) {
            const listOfFiles = cueOfFiles?.[entity.entity] || null;
            saveFilesPostSave(id, listOfFiles)
                .then(() => {
                    resolve(result);
                    this.afterSave({
                        manager: entityManager,
                        schema,
                        data: serverData,
                        result,
                        entity,
                        isModal,
                        originalData,
                    });
                })
                .catch((e) => {
                    console.error(e);
                });
        } else {
            resolve(result);
            this.afterSave({
                manager: entityManager,
                schema,
                data: serverData,
                result,
                entity,
                isModal,
                originalData,
            });
        }
    }

    save({
        entity,
        entityState,
        persistCrudWorkFlow,
        fromDetail = false,
        fromWorkFlow = false,
        customState,
        hasFilesToSave,
    }) {
        const entityManager = this.context.entityManager.getEntitiesManager(entity);

        const { schema, dynamicHiddenMap, data, isModal, ignoreErrors, originalData } =
            customState || entityState;
        return new Promise((resolve, reject) => {
            this.checkErrors({
                entity,
                schema,
                dynamicHiddenMap,
                data,
                action: 'onSave',
            })
                .then((errorsResult) => {
                    const { firstErrorField, ...errors } = errorsResult;

                    // if we have fromWorkFlow true is because we are here after an action, so
                    // the save of this entity did already pass the validation and,
                    // because we change between activities form of web3 and entity of web4,
                    // we don't have the crud maps updated with all the changed fields
                    if (firstErrorField && !fromWorkFlow && !ignoreErrors)
                        return reject({ errors, firstErrorField });

                    // when we come from a workFlow action, because we don't fill workFlowAction object
                    // into the state, we can save in a good way. Anyway, when SPA, we are going to need
                    // to change this, like all the persisting data of sessionStorage
                    if (
                        entityState?.workFlowAction &&
                        !isEmptyObject(entityState.workFlowAction) &&
                        persistCrudWorkFlow
                    ) {
                        sessionStorage.setItem(
                            'fm_workflow_crud_persist',
                            JSON.stringify(persistCrudWorkFlow),
                        );
                        this.redirectForWorkFlow(
                            entityState.workFlowAction,
                            entity,
                            data,
                            fromDetail,
                        );
                        return;
                    }

                    // Default preprocess
                    let serverData = entityManager.processForServer
                        ? entityManager.processForServer(schema, data, false, dynamicHiddenMap)
                        : this.processForServer(schema, data, false, dynamicHiddenMap);

                    const saveFilesPostSave = entityManager.saveFilesPostSave || null;

                    // Custom preprocess
                    serverData = this.beforeSave(entityManager, schema, serverData);
                    if (!entity.useNewApi) {
                        if (data.id) {
                            this.context.domainManager.updateEntity(
                                entity.entity,
                                data.id,
                                serverData,
                                (result) => {
                                    result = this.manageResultSave(
                                        entityManager,
                                        schema,
                                        serverData,
                                        result,
                                    );

                                    this.finishSave({
                                        data,
                                        hasFilesToSave,
                                        saveFilesPostSave,
                                        resolve,
                                        result,
                                        entityManager,
                                        schema,
                                        serverData,
                                        result,
                                        entity,
                                        isModal,
                                        originalData,
                                    });
                                },
                                (error) => {
                                    console.error(error);
                                    reject({
                                        serverError: error.error || true,
                                    });
                                },
                            );
                        } else {
                            this.context.domainManager.createEntity(
                                entity.entity,
                                serverData,
                                (result) => {
                                    result = this.manageResultSave(
                                        entityManager,
                                        schema,
                                        serverData,
                                        result,
                                    );

                                    this.finishSave({
                                        hasFilesToSave,
                                        saveFilesPostSave,
                                        resolve,
                                        result,
                                        entityManager,
                                        schema,
                                        serverData,
                                        result,
                                        entity,
                                        isModal,
                                        originalData,
                                    });
                                },
                                (error) => {
                                    console.error(error);
                                    reject({
                                        serverError: error.error || true,
                                    });
                                },
                            );
                        }
                    } else if (data.id) {
                        EntityCrudService.update(entity, serverData)
                            .then((result) => {
                                result = this.manageResultSave(
                                    entityManager,
                                    schema,
                                    serverData,
                                    result,
                                );

                                this.finishSave({
                                    hasFilesToSave,
                                    saveFilesPostSave,
                                    resolve,
                                    result,
                                    entityManager,
                                    schema,
                                    serverData,
                                    result,
                                    entity,
                                    isModal,
                                    originalData,
                                });
                            })
                            .catch((error) => {
                                console.error(error);
                                reject({
                                    serverError: error.error || true,
                                });
                            });
                    } else {
                        EntityCrudService.create(entity, serverData)
                            .then((result) => {
                                result = this.manageResultSave(
                                    entityManager,
                                    schema,
                                    serverData,
                                    result,
                                );

                                this.finishSave({
                                    hasFilesToSave,
                                    saveFilesPostSave,
                                    resolve,
                                    result,
                                    entityManager,
                                    schema,
                                    serverData,
                                    result,
                                    entity,
                                    isModal,
                                    originalData,
                                });
                            })
                            .catch((error) => {
                                console.error(error);
                                reject({
                                    serverError: error.error || true,
                                });
                            });
                    }
                })
                .catch((e) => {
                    console.error(e);
                    reject(e);
                });
        });
    }

    delete(entity, entityState) {
        const entityManager = this.context.entityManager.getEntitiesManager(entity);
        const { data, schema, isModal } = entityState;
        return new Promise((resolve, reject) => {
            this.beforeDelete(entityManager, schema, data);

            if (!entity.useNewApi) {
                this.context.domainManager.deleteEntity(
                    entity.entity,
                    data.id,
                    (result) => {
                        if (result && result.Deleted && result.Deleted.num === '1') {
                            resolve(data);
                            this.afterDelete({ manager: entityManager, schema, data, isModal });
                        } else {
                            reject({
                                serverError: true,
                            });
                        }
                    },
                    (error) => {
                        console.error(error);
                        reject({
                            serverError: error.error || true,
                        });
                    },
                );
            } else {
                // if we need to customize the default deleteEntity, add the default
                // parameters in the variable and customize what you want for the service
                const parameters =
                    entityManager && entityManager.calculateDeleteEntityParameters
                        ? entityManager.calculateDeleteEntityParameters(data.id)
                        : null;
                EntityCrudService.del(entity, data.id, parameters)
                    .then((result) => {
                        resolve(result);
                        this.afterDelete({ manager: entityManager, schema, data, isModal });
                    })
                    .catch((error) => {
                        console.error(error);
                        reject({
                            serverError: error.error || true,
                        });
                    });
            }
        });
    }

    checkCustomValidationError = (action, field, data, entity) => {
        return new Promise((resolve, reject) => {
            if (!action || !field?.fieldValidation || (!field.mandatory && !data)) {
                resolve('');
                return;
            }
            const sameEntity =
                customValidations?.[action]?.[field.fieldValidation]?.entity === entity?.trueName;

            const validationCode =
                customValidations?.[action]?.[field.fieldValidation]?.validationCode || null;

            if (!validationCode || !sameEntity) {
                resolve('');
            } else {
                const customValidationError = executeSandbox(data, validationCode);
                resolve(customValidationError);
            }
        });
    };

    checkDateError = (field, data) => {
        let error = null;
        const date = new Date(data);

        if (data && date && date.getFullYear() < 1900) error = 'invalid-date';
        if (!data && field.mandatory) error = 'mandatory';
        return error;
    };

    checkErrors({ entity, schema, dynamicHiddenMap = {}, data, action }) {
        const config = this.context.cacheManager.getConfigStore();
        return new Promise((resolve, reject) => {
            let fieldValues = {};
            let errors = {};

            let firstErrorField = false;
            // we need field order for first error focus
            let sortedDuplicatedFields = [];
            // we need a map for field name mapping between duplicate id and our field id...
            let duplicatedFieldsMap = {};

            const manager = this.context.entityManager.getEntitiesManager(entity);
            const promises = [];

            schema.forEach((item) => {
                item?.fields?.forEach((field) => {
                    let inputs = [field];
                    const specialFieldTypes = ['multipleField', 'multipleFuzzySearchSingle'];
                    if (
                        specialFieldTypes.includes(field.type) ||
                        (field.inputs && field.inputs.length > 0 && field.isCustom)
                    ) {
                        inputs = field.inputs;
                    }

                    inputs.forEach((field) => {
                        const id = field.id;
                        const value = data[id];

                        // Duplicates auxiliar variables
                        if (field.checkDuplicates && value) {
                            fieldValues[field.checkDuplicateId] = value;
                            duplicatedFieldsMap[field.checkDuplicateId] = field;
                            sortedDuplicatedFields.push(field);
                        }

                        promises.push(
                            this.checkCustomValidationError(action, field, value, entity).then(
                                (customValidationError) => {
                                    const readOnly = field.readOnly && data.id;
                                    let checkFunction = null;

                                    if (field.hidden || dynamicHiddenMap[field.fieldConfiguration])
                                        return;

                                    switch (field.type) {
                                        case 'dateRange':
                                            checkFunction = this.checkRangeFieldError;
                                            break;
                                        case 'text':
                                        case 'textarea':
                                            checkFunction = this.checkTextFieldError;
                                            break;
                                        case 'url':
                                            checkFunction = this.checkUrlFieldError;
                                            break;
                                        case 'percent':
                                            checkFunction = this.checkPercentFieldError;
                                            break;
                                        case 'bool':
                                            checkFunction = () => null;
                                            break;
                                        case 'date':
                                            checkFunction = this.checkDateError;
                                            break;

                                        case 'singleValueList':
                                        case 'fuzzySearchSingle':
                                        case 'multipleValueList':
                                        case 'fuzzySearchMultiple':
                                            checkFunction = this.checkValueListError;
                                            break;

                                        default:
                                            checkFunction = this.checkSimpleFieldError;
                                            break;
                                    }

                                    let error = checkFunction(field, value);

                                    // Use specific entity checking error function
                                    if (!error && manager && manager.checkFieldError) {
                                        error = manager.checkFieldError(field, value, item.fields);
                                    }

                                    if (error && !readOnly) {
                                        errors[id] = error;
                                        if (!firstErrorField) firstErrorField = id;
                                    }

                                    if (!error && customValidationError && !readOnly) {
                                        errors[id] = getLiteral(customValidationError);
                                        if (!firstErrorField) firstErrorField = id;
                                    }
                                },
                            ),
                        );
                    });
                });
            });

            Promise.all(promises)
                .then(() => {
                    if (manager && manager.checkCustomErrors) {
                        errors = manager.checkCustomErrors(errors, schema, data);
                        if (errors.hasOwnProperty('firstErrorField')) {
                            firstErrorField = errors.firstErrorField;
                            delete errors.firstErrorField;
                        }
                        if (!firstErrorField && !isEmptyObject(errors)) {
                            firstErrorField = Object.keys(errors)[0];
                        }
                    }
                })
                .then(() => {
                    if (
                        firstErrorField ||
                        config?.permisos?.checkDuplicateFields !== '2' ||
                        !entity.checkDuplicates
                    )
                        return resolve({ ...errors, firstErrorField });

                    this.context.store.dispatch(
                        this.context.actions.EntityCrudActions.toggleDuplicatesOverlay(true),
                    );

                    this.context.domainManager
                        .checkDuplicateFields(entity.trueName, fieldValues, data?.id)
                        .then((checks) => {
                            if (checks?.length) {
                                errorToast({
                                    text: getLiteral('label_cannot_save_due_to_duplicate_found'),
                                });

                                // Get first check by our form field order
                                const checksMap = checks.reduce((obj, check) => {
                                    obj[check.fieldName] = check;
                                    return obj;
                                }, {});

                                const firstFieldCheck = sortedDuplicatedFields.find(
                                    (field) => !!checksMap[field.checkDuplicateId],
                                );

                                firstErrorField = firstFieldCheck?.id;

                                checks.forEach((check) => {
                                    errors[duplicatedFieldsMap[check.fieldName].id] =
                                        getLiteralWithParameters(
                                            'label_entity_already_used_duplicated_web',
                                            [check.name],
                                        );
                                });

                                // We need a flag for avoid save without changes and check duplicates again.
                                this.context.store.dispatch(
                                    this.context.actions.EntityCrudActions.setDuplicatesError(),
                                );
                            }
                        })
                        .catch((error) => reject(error))
                        .then(() => {
                            this.context.store.dispatch(
                                this.context.actions.EntityCrudActions.toggleDuplicatesOverlay(
                                    false,
                                ),
                            );
                            resolve({ ...errors, firstErrorField });
                        });
                })
                .catch(reject);
        });
    }

    checkSimpleFieldError = (field, data) => {
        let error = null;
        if (!data && field.mandatory) error = 'mandatory';
        if (field.mandatory && Array.isArray(data) && data.length === 0) error = 'mandatory';
        return error;
    };

    checkValueListError = (field, data) => {
        let error = null;
        if (!data && field.mandatory) error = 'mandatory';
        // why backend, why
        if ((data === '-1' || data === -1) && field.mandatory) error = 'mandatory';
        if (field.mandatory && Array.isArray(data) && data.length === 0) error = 'mandatory';
        return error;
    };

    checkRangeFieldError = (field, data) => {
        let error = null;
        const to = field.inputAttrs.to;
        const from = field.inputAttrs.from;

        // Checking mandatory errors
        if (field.mandatory) {
            if (data && !data[to]) {
                error = { [to]: 'mandatory' };
            }

            if (data && !data[from]) {
                if (!error) error = {};
                error[from] = 'mandatory';
            }

            if (!data) {
                error = {
                    [to]: 'mandatory',
                    [from]: 'mandatory',
                };
            }
        }

        return error;
    };

    checkTextFieldError = (field, data) => {
        let error = this.checkSimpleFieldError(field, data);
        if (error === 'mandatory') return error;
        if (data && field.dataLength && data.length > field.dataLength && field.dataLength !== -1) {
            error = 'length-not-allowed';
        }
        return error;
    };

    checkUrlFieldError = (field, data) => {
        let error = this.checkSimpleFieldError(field, data);
        if (error === 'mandatory') return error;

        // Max length
        if (data && field.dataLength && data.length > field.dataLength && field.dataLength !== -1) {
            error = 'length-not-allowed';
        }

        // Url
        if (data && !REG_EX_URL_ONLY.test(data)) {
            error = 'url-not-valid';
        }

        return error;
    };

    checkPercentFieldError = (field, data) => {
        const error = this.checkSimpleFieldError(field, data);
        if (error === 'mandatory' && data !== 0) return error;
        return null;
    };

    getCustomValidations = (entity) => {
        return new Promise((resolve, reject) => {
            const state = Context.store.getState();
            const customValidationsSchemaMap = state.config?.customValidationsSchemaMap || {};
            const entityCustomValidations = customValidationsSchemaMap[entity.trueName] || [];
            if (entityCustomValidations?.length === 0) return resolve();
            let requests = [];
            entityCustomValidations.forEach((current) => {
                if (
                    current.scope === 'field' &&
                    current.type === 'validation' &&
                    current.linkToCode
                ) {
                    requests.push(
                        axios.get(current.linkToCode).then(({ data }) => {
                            let validation = current;
                            validation.validationCode = data;
                            if (!customValidations[current.userAction]) {
                                customValidations[current.userAction] = {};
                            }

                            customValidations[current.userAction][current.fieldV4] = validation;
                        }),
                    );
                }
            }, []);

            Promise.all(requests)
                .then(resolve)
                .catch(() => resolve());
        });
    };

    getCrudPreComponents = (entity) => {
        const manager = this.context.entityManager.getEntitiesManager(entity);
        if (manager && manager.getCrudPreComponents) return manager.getCrudPreComponents(entity);
        return Promise.resolve([]);
    };

    getQueryStringData = ({ mappedSchema }) => {
        let data = {};
        let params = [];
        let fuzzyRequest = [];
        let entity;
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        urlParams.forEach((value, key) => {
            if (mappedSchema[key]) params.push({ key, value, config: mappedSchema[key] });
        });

        data = params.reduce((obj, param) => {
            if (!param.value) return obj;
            switch (param.config.type) {
                case 'text':
                case 'textarea':
                    obj[param.key] = param.value;
                    break;
                case 'singleValueList':
                    obj[param.key] = param.value;
                    break;
                case 'multipleValueList':
                    obj[param.key] = param.value?.split(',')?.filter((el) => el !== '');
                    break;
                case 'number':
                case 'integer':
                    obj[param.key] = toNumber(param.value);
                    break;
                case 'decimal':
                case 'currency':
                case 'percent':
                    obj[param.key] = toDecimal(param.value);
                    break;
                case 'bool':
                    obj[param.key] = toBool(param.value);
                    break;
                case 'dateTime':
                case 'datetime':
                case 'date':
                    obj[param.key] = toDate(param.value);
                    break;
                case 'fuzzySearchSingle':
                    entity = FUZZY_ENTITIES[param.config?.inputAttrs?.list];
                    if (entity) {
                        fuzzyRequest.push({
                            key: param.key,
                            ids: [param.value],
                            entity,
                            type: 'fuzzySearchSingle',
                        });
                    }
                    break;
                case 'fuzzySearchMultiple':
                    entity = FUZZY_ENTITIES[param.config?.inputAttrs?.list];
                    if (entity) {
                        const ids = param.value?.split(',')?.filter((el) => el !== '');
                        fuzzyRequest.push({
                            key: param.key,
                            ids: ids,
                            entity,
                            type: 'fuzzySearchMultiple',
                        });
                    }
                    break;
                default:
                    break;
            }
            return obj;
        }, {});

        // Removing query string params
        const url = new URL(window.location);
        url.search = '';
        window.history.replaceState({}, '', url);

        if (fuzzyRequest) {
            return Promise.all(
                fuzzyRequest.map((req) => getEntityValue(req.entity.trueName, req.ids)),
            ).then((responses) => {
                fuzzyRequest.forEach((req, idx) => {
                    if (req.type === 'fuzzySearchSingle') {
                        const value = responses?.[idx]?.data?.[String(req.ids[0])];
                        if (!value) return;
                        data[req.key] = { label: value.description, value: value.id };
                    } else {
                        if (!data[req.key]) data[req.key] = [];
                        req.ids.forEach((id) => {
                            const value = responses?.[idx]?.data?.[String(id)];
                            if (!value) return;
                            data[req.key].push({ label: value.description, value: value.id });
                        });
                    }
                });
                return data;
            });
        } else {
            return Promise.resolve(data);
        }
    };

    processForServer = (schema, data, isBulk, dynamicHiddenMap = {}) => {
        let finalData = { ...data };

        schema?.forEach((tab) => {
            tab.fields.forEach((field) => {
                if (field.readOnly && !finalData[field.id]) return;
                if (isBulk) {
                    if (data[field.id] || field.inputs) {
                        const tempData = this.processFields(
                            field,
                            finalData,
                            isBulk,
                            dynamicHiddenMap,
                        );
                        finalData = { ...tempData };
                    }
                } else {
                    const tempData = this.processFields(field, finalData, false, dynamicHiddenMap);
                    finalData = { ...tempData };
                }
            });
        });

        return finalData;
    };

    processFields = (field, data, isBulk, dynamicHiddenMap, useNewApi) => {
        let finalData = { ...data };

        let value = finalData[field.id];
        const finalId = field.serverId || field.id;

        // Server id expansion for ranges
        if (
            field.serverId &&
            typeof field.serverId === 'object' &&
            value &&
            field.serverId.to &&
            field.serverId.from
        ) {
            const to = field.serverId.to;
            const from = field.serverId.from;
            finalData[to] = value[to];
            finalData[from] = value[from];
        }

        // Server id expansion for rich text
        if (
            field.serverId &&
            typeof field.serverId === 'object' &&
            value &&
            field.serverId.html &&
            field.serverId.text
        ) {
            const html = field.serverId.html;
            const text = field.serverId.text;
            finalData[html] = (value.html || '').trim();
            finalData[text] = (value.text || '').trim();
        }

        switch (field.type) {
            case 'geolocation':
            case 'multipleField':
            case 'multipleCustom':
                if (field.hasOwnProperty('inputs')) {
                    field.inputs.forEach((fieldInside) => {
                        // Logic added in order to return currency value as 0 when it is sent as null
                        const finalIdInside = fieldInside.serverId || fieldInside.id;
                        const valueInside = finalData[fieldInside.id];
                        if ((isBulk && valueInside) || !isBulk) {
                            finalData[finalIdInside] = valueInside || '';
                        }
                        if (fieldInside.serverId && fieldInside.serverId !== fieldInside.id)
                            delete finalData[fieldInside.id];
                    });
                }
                break;
            case 'fuzzySearchSingle':
            case 'fuzzySearch':
                if (value && value.value) {
                    finalData[finalId] = value.value;
                } else {
                    finalData[finalId] = '-1';
                }
                break;
            case 'fuzzySearchMultiple':
                if (value && Array.isArray(value) && value.length > 0) {
                    const values = value.reduce((obj, val) => {
                        obj.push(val.Id || val.value);
                        return obj;
                    }, []);
                    finalData[finalId] = `;${values.join(';')};`;
                }
                break;
            case 'singleValueList':
                // we can have singleValueList printed as a chip
                if (value && value.value) {
                    finalData[finalId] = value.value;
                } else {
                    finalData[finalId] = value || '-1';
                }
                break;
            case 'multipleValueList':
                value = value || [];
                finalData[finalId] = Array.isArray(value)
                    ? value.map((v) => v?.value || v).join(';')
                    : value;
                if (value && value.length > 0) finalData[finalId] = `;${finalData[finalId]};`;
                break;
            case 'date':
            case 'dateTime':
            case 'dateTimeGroup':
                if (value) finalData[finalId] = formatDateForServer(value);
                else finalData[finalId] = '';

                if (field.customProcessField) {
                    finalData = field.customProcessField(field, finalData);
                }
                break;
            case 'multipleFuzzySearchSingle':
                if (isBulk) {
                    this.formatMultipleFuzzySearchSingleDataForBulk(finalData, field);
                } else if (field.customProcessField) {
                    finalData = field.customProcessField(field, finalData);
                } else {
                    this.formatMultipleFuzzySearchSingleData(finalData, field);
                }

                break;
            case 'text':
            case 'textarea':
            case 'url':
                finalData[finalId] = (value || '').trim();
                break;
            case 'bool':
                if (typeof value !== 'boolean') {
                    value = value === '1' ? '1' : '0';
                }
                finalData[finalId] = value;
                break;
            default:
                finalData[finalId] = value;
                break;
        }

        // Removing unneeded data
        if (field.serverId && field.serverId !== field.id) delete finalData[field.id];

        // Server type conversion
        if (field.serverType && value) {
            finalData[finalId] = field.serverType(finalData[finalId]);
        }

        // Removing non-visible fields data
        if (
            !field.hasDefaultUserValues &&
            (field.hidden || field.dynamicHidden || dynamicHiddenMap[field.fieldConfiguration])
        ) {
            delete finalData[finalId];
        }

        return finalData;
    };

    formatMultipleFuzzySearchSingleData = (finalData, field) => {
        let itemsIdsArr = [];
        let itemsIds;

        // Had to do this because the way we receive the data from backend
        if (field.id === 'idComercialMultiple') {
            field.inputs.forEach((input) => {
                if (input.id === field.inputs[0].id && finalData[input.id]) {
                    //TODO check when enters in this if. Because this finalData is getting override at the end of the method :S
                    finalData[input.serverId] = finalData[input.id].value;
                    delete finalData[input.id];
                    return; //Added a return to prevent what I explain in the TODO
                } else if (finalData[input.id]) {
                    itemsIdsArr = [...itemsIdsArr, finalData[input.id].value];
                    delete finalData[input.id];
                }
            });
            // Backend wants the string with a final ';' :|
            // When is empty they expect a ';'
            itemsIds = itemsIdsArr.length > 0 ? `${itemsIdsArr.join(';')};` : ';';
        } else if (field.id === 'idContactMultiple') {
            field.inputs.forEach((input) => {
                if (finalData[input.id]) {
                    itemsIdsArr = [...itemsIdsArr, finalData[input.id].value];
                    delete finalData[input.id];
                }
            });
            // Backend doesn't want the string with a final ';' :|
            // When is empty they expect a ';'
            itemsIds = itemsIdsArr.length > 0 ? itemsIdsArr.join(';') : ';';
        }
        if (field.keyIds) finalData[field.keyIds] = itemsIds;

        // another case for backend. Responsibles of companies need to be in separated fields
        if (field.id === 'idResponsibleMultiple') {
            field.inputs.map((input) => {
                if (finalData[input.id]) {
                    finalData[input.serverId] = finalData[input.id].value;
                } else {
                    finalData[input.serverId] = '';
                }
                if (input.serverId !== input.id) delete finalData[input.id];
            });
        }
    };

    formatMultipleFuzzySearchSingleDataForBulk = (finalData, field) => {
        if (field.inputs) {
            field.inputs.forEach((input) => {
                if (finalData[input.id]) {
                    finalData[input.serverId] = finalData[input.id].value;
                }
                if (input.serverId !== input.id) delete finalData[input.id];
            });
        }
    };

    redirectForWorkFlow = (workFlowAction, entity, data, fromDetail) => {
        // at this point, workFlowAction have more info of the action,
        // but the array of the actions is inside. Check the action
        // if you need to be sure
        let action = workFlowAction.actions[0];
        if (action.type === 'create') {
            if (action.entity === 'activity') {
                let url = `${ACTIVITIES.route}/new/${ACTIVITY_TYPE_ANNOTATION}`;
                // prepare data for web3
                let fieldsProperties = [...action.fieldsProperties];
                fieldsProperties.push({
                    name: entity.entity,
                    readOnly: false,
                    value: data.id,
                    valueText: data.reference || data.name || '', // this value depends on the entity
                });
                sessionStorage.setItem('fm_workflow_action', JSON.stringify(fieldsProperties));
                sessionStorage.setItem('fm_workflow_action_fieldName', workFlowAction.fieldName);
                sessionStorage.setItem('fm_workflow_action_detail', fromDetail);
                sessionStorage.setItem('fm_workflow_action_id', action.idAction);
                window.location = url;
            }
        }
    };

    checkDuplicate(entity, field, value) {
        return new Promise((resolve, reject) => {
            this.context.domainManager
                .getDuplicateForFields(entity, field, value)
                .then((data) => resolve(data))
                .catch((error) => reject(error));
        });
    }

    checkDuplicateFields(entity, fieldValues) {
        return new Promise((resolve, reject) => {
            this.context.domainManager
                .checkDuplicateFields(entity, fieldValues)
                .then((data) => resolve(data))
                .catch((error) => reject(error));
        });
    }
}
