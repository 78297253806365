import { memo, useMemo } from 'react';
import { GridLayout } from '@web/web5';
import ActivitiesDashboardWidget from './widgets/ActivitiesDashboardWidget';
import TaskDashboardWidget from './widgets/TaskDashboardWidget';
import UpcomingNextEventDashboardWidget from './widgets/UpcomingNextEventDashboardWidget';
import OpportunitiesDashboardWidget from './widgets/OpportunitiesDashboardWidget';

const DashboardGrid = memo(() => {
    const layouts = useMemo(() => {
        const base = { isDraggable: false, isResizable: false };
        const mdsm = [
            { i: 'upcoming-next-events-dashboard-widget', w: 8, h: 24, x: 0, y: 0, ...base },
            { i: 'goals-dashboard-widget', w: 8, h: 12, x: 8, y: 0, ...base },
            { i: 'activities-dashboard-widget', w: 8, h: 12, x: 8, y: 0, ...base },
            { i: 'tasks-dashboard-widget', w: 8, h: 12, x: 0, y: 12, ...base },
            { i: 'opportunity-dashboard-widget', w: 8, h: 12, x: 8, y: 12, ...base },
        ];
        return {
            lg: [
                { i: 'upcoming-next-events-dashboard-widget', w: 8, h: 24, x: 0, y: 0, ...base },
                { i: 'goals-dashboard-widget', w: 8, h: 12, x: 8, y: 0, ...base },
                { i: 'activities-dashboard-widget', w: 8, h: 12, x: 16, y: 0, ...base },
                { i: 'tasks-dashboard-widget', w: 8, h: 12, x: 8, y: 12, ...base },
                { i: 'opportunity-dashboard-widget', w: 8, h: 12, x: 16, y: 12, ...base },
            ],
            md: mdsm,
            sm: mdsm,
            xs: mdsm,
            xxs: mdsm,
        };
    }, []);

    const widgets = useMemo(() => {
        return [
            {
                id: 'upcoming-next-events-dashboard-widget',
                content: <UpcomingNextEventDashboardWidget />,
            },
            { id: 'goals-dashboard-widget', content: <span>Goal widget under construction</span> },
            { id: 'activities-dashboard-widget', content: <ActivitiesDashboardWidget /> },
            { id: 'tasks-dashboard-widget', content: <TaskDashboardWidget /> },
            {
                id: 'opportunity-dashboard-widget',
                content: <OpportunitiesDashboardWidget />,
            },
        ];
    }, []);

    return (
        <div className="fm-dashboard__grid">
            <GridLayout layouts={layouts} widgets={widgets} adaptativeHeight />
        </div>
    );
});

export default DashboardGrid;
