import React, { memo, useState, useMemo } from 'react';
import { Text, Icon, useTheme, SelectWrapper, Select, Chip, Tooltip } from 'hoi-poi-ui';

import Context from 'managers/Context';
import { getLiteral } from 'utils/getLiteral';
import './styles.scss';

const DashboardWidgetHeader = memo(
    ({
        title,
        // Actions
        onViewMore,
        onAdd,
        addTooltip,
        // Filter
        filterLabel,
        filterOptions,
        filterDefaultValue = [],
        onChangeFilter,
        filterIsMulti,
    }) => {
        const theme = useTheme();
        const [isSelectWrapperOpen, setIsSelectWrapperOpen] = useState(false);
        const [filter, setFilter] = useState(filterDefaultValue);

        const widgetFilter = useMemo(() => {
            if (!filterOptions || !onChangeFilter) return null;
            let inputProps = {
                options: filterOptions || undefined,
                placeholder: getLiteral('label_selectone'),
            };

            let chipLabel = filterLabel;
            if (
                (Array.isArray(filter) && filter?.length === 1) ||
                (!Array.isArray(filter) && filter)
            ) {
                chipLabel = filter?.[0]?.label || filter?.label;
            } else if (Array.isArray(filter) && filter?.length) {
                chipLabel = `${filterLabel} (${filter?.length})`;
            }

            return (
                <SelectWrapper
                    className="fm-dashboard-widget__filter"
                    overlayInnerStyle={{ width: '300px' }}
                    getIsOpen={(isOpen) => setIsSelectWrapperOpen(isOpen)}
                    overrides={{
                        Popover: {
                            root: {
                                visible: isSelectWrapperOpen,
                            },
                        },
                    }}
                    customOptions={
                        <Select
                            {...inputProps}
                            value={filter}
                            onChange={(value) => {
                                setFilter(value);
                                onChangeFilter(value);
                            }}
                            isFullWidth
                            menuPosition="absolute"
                            useMenuPortal={false}
                            forceMenuIsOpen={isSelectWrapperOpen}
                            forceStartFocused={isSelectWrapperOpen}
                            isMulti={filterIsMulti}
                        />
                    }
                >
                    <Chip isUnfolded={true} isFilled={filter?.length > 0}>
                        {chipLabel}
                    </Chip>
                </SelectWrapper>
            );
        }, [
            filterOptions,
            onChangeFilter,
            filterLabel,
            filter,
            isSelectWrapperOpen,
            filterIsMulti,
        ]);

        const showDivider = (onViewMore || onAdd) && !!widgetFilter;
        return (
            <div className="fm-dashboard-widget__header">
                <Text type="subtitle">{title}</Text>
                <div className="fm-dashboard-widget__header-actions">
                    {onViewMore && (
                        <Tooltip content={getLiteral('placeholder_view_more')}>
                            <span>
                                <Icon
                                    name="launch"
                                    color={theme.colors.orange500}
                                    onClick={onViewMore}
                                />
                            </span>
                        </Tooltip>
                    )}
                    {onAdd && (
                        <Tooltip content={addTooltip}>
                            <span>
                                <Icon
                                    name="addCircle"
                                    color={theme.colors.orange500}
                                    size="large"
                                    onClick={onAdd}
                                />
                            </span>
                        </Tooltip>
                    )}
                    {showDivider && <div className="fm-dashboard-widget__divider"></div>}
                    {widgetFilter}
                </div>
            </div>
        );
    },
);

export default DashboardWidgetHeader;
