import { memo, useCallback, useState, useMemo, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Text, Spacer, Link, Icon, useTheme, Avatar, Popover } from 'hoi-poi-ui';
import moment from 'moment';
import { rrulestr } from 'rrule';

import { EntityListActions } from 'actions';
import Context from 'managers/Context';
import { AGENDA, COMPANIES } from 'constants/Entities';
import { AGENDA_EVENT } from 'constants/Constants';
import { EntityModalActions } from 'actions';
import AgendaModel from 'models/AgendaModel';

import {
    formatDateToBackendUTC,
    getPreviousAndNextDate,
    getActivityDateFormat,
    getDateToTimezone,
} from 'utils/dates';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';
import { getRoute, ensureRoute } from 'utils/routes';
import { logEvent } from 'utils/tracking';
import { getNewStrRuleWithOffset } from 'utils/rrule';
import { getBackendBoolean } from 'utils/fm';
import { getCompanyDetailUrl } from 'utils/getUrl';

import { subscribe } from 'lib/EventBuser';
import { ENTITY_MODAL_UPDATE } from 'lib/events';

import useEntityDetail from 'containers/components/EntityDetail/hooks/useEntityDetail';
import CompanyChip from 'containers/components/chips/CompanyChip';
import DashboardWidget from '../components/DashboardWidget';
import DashboardWidgetHeader from '../components/DashboardWidget/DashboardWidgetHeader';
import EntityDetailPopover from 'containers/components/EntityDetailPopover';
import AgendaSmallDetail from 'containers/agenda/components/AgendaSmallDetail';
import DashboardWidgetRow from '../components/DashboardWidgetRow';
import CompanyAvatar from 'containers/components/avatars/CompanyAvatar';
import AttendeesField from '../../agenda/components/AgendaSmallDetail/fields/AttendeesField';
import VideoCallField from '../../agenda/components/AgendaSmallDetail/fields/VideoCallField';
import AttendeesList from '../../agenda/components/AgendaSmallDetail/attendees/AttendeesList';

const EMPTY_SVG = (
    <svg xmlns="http://www.w3.org/2000/svg" width={117} height={100} fill="none">
        <g clipPath="url(#a)">
            <path
                fill="#D4D8DC"
                d="M98.55 84.676H77.347v-.615c0-.534.224-1.046.624-1.423.399-.378.94-.59 1.506-.59H96.42c.565 0 1.107.212 1.506.59.4.377.624.89.624 1.423v.615Z"
            />
            <path
                fill="#D4D8DC"
                d="m87.452 82.17.645-.245C81.35 66.1 82 46.135 87.704 29.01c.677-1.984.726-4.113.14-6.123s-1.78-3.814-3.437-5.191c-3.31-2.792-7.874-3.472-11.911-1.775-3.985 1.675-4.534 4.592-4.534 7.496h.695c0-3.1.7-5.458 4.121-6.896 3.784-1.59 8.062-.952 11.166 1.665 1.556 1.293 2.678 2.988 3.228 4.876a9.516 9.516 0 0 1-.132 5.75c-5.769 17.324-6.38 37.426.412 53.359Z"
            />
            <path
                fill="#D4D8DC"
                d="M68.483 33.106c1.344 0 2.433-1.03 2.433-2.299 0-1.27-1.09-2.3-2.433-2.3-1.344 0-2.433 1.03-2.433 2.3 0 1.27 1.09 2.3 2.433 2.3ZM64.736 34.816v-4.357h.716v4.357a.94.94 0 0 1 .482.406.86.86 0 0 1 .098.603.895.895 0 0 1-.33.525.99.99 0 0 1-.608.206.99.99 0 0 1-.608-.206.896.896 0 0 1-.33-.525.86.86 0 0 1 .098-.603.94.94 0 0 1 .482-.407Z"
            />
            <path
                fill="#FFF1CC"
                d="M61.27 27.112a3.68 3.68 0 0 1 1.172-2.67 4.123 4.123 0 0 1 2.826-1.108h6.604c1.06 0 2.077.398 2.826 1.107.75.708 1.171 1.669 1.171 2.67a3.675 3.675 0 0 1-1.17 2.672 4.118 4.118 0 0 1-2.827 1.106h-6.604a4.123 4.123 0 0 1-2.826-1.107 3.68 3.68 0 0 1-1.172-2.67Z"
            />
            <path
                fill="#98D5F1"
                d="M51.965 50.504c.663-8.017 4.053-11.837 1.883-14.232-2.08-2.294-7.937-1.82-11.699.382-10.146 5.938-13.864 29.606-.807 41.17C53.75 88.81 77.264 85.291 82.49 76.297c1.22-2.098 2.188-5.777.404-8.005-4.43-5.531-20.539 4.487-27.7-1.779-4.245-3.713-3.592-11.61-3.228-16.01Z"
            />
            <path
                fill="#FFC5AC"
                d="M59.721 46.521a1.01 1.01 0 0 0-.536.54.934.934 0 0 0 .093.877L57.1 50.686l1.087 1.535 2.553-4.069a.98.98 0 0 0 .367-.53.93.93 0 0 0-.051-.63.94.94 0 0 0-.223-.3 1.003 1.003 0 0 0-.327-.197 1.047 1.047 0 0 0-.785.026Z"
            />
            <path
                fill="#FF8C00"
                d="M59.72 51.259c.142.12-.253.267-.672.034-.12-.067-.17-.095-.184-.078-.033.045.163.373.11 1.1-.032.436-.796 1.253-1.663 2.05a41.736 41.736 0 0 1-2.536 2.116l-.479-.362-5.02-3.803-.847-1.108c.078-.438.194-.868.349-1.287.212-.58.465-1.147.755-1.695.156-.299.316-.584.47-.845l2.69 2.8 2.253 2.346s.656-.999 1.164-1.27a.502.502 0 0 1 .215-.07c.514-.024.545-.288-.05-.863-.596-.576.155-.039.685-.194s0 0 .112-.387c.111-.388 1.597-1.165 1.597-1.165l.062.052 1.123.936.321.268s-.35.91-.448 1.102c-.096.192-.149.202-.008.323Z"
            />
            <path fill="#FFC5AC" d="m89.318 68.91.055-1.401-5.695-.867-.081 2.069 5.72.2Z" />
            <path
                fill="#FFB13F"
                d="m88.284 69.332-.054-2.244 1.015.035 1.64-2.074a.636.636 0 0 1 .323-.215.67.67 0 0 1 .396.008.631.631 0 0 1 .313.228.575.575 0 0 1 .11.36l-.104 2.665-1.022.425.997.214-.04 1.006-3.574-.408Z"
            />
            <path
                fill="#37526C"
                d="m57.266 69.456 18.038.432s7.79.425 9.617-.698l1.927-.23.105-2.675s-1.179.008-1.856-.29c-.678-.299-.207-.664-.853-.305-.646.36-.578.402-1.077.237-.5-.165-23.404-3.464-23.404-3.464l-2.497 6.993Z"
            />
            <path fill="#FFC5AC" d="m81.035 68.295 1.05-.992-3.552-4.295-1.548 1.464 4.05 3.823Z" />
            <path
                fill="#FFB13F"
                d="m79.974 67.432 1.575-1.68.72.678 2.694-.467a.668.668 0 0 1 .392.05.62.62 0 0 1 .284.26.571.571 0 0 1-.112.704l-1.995 1.887-1.055-.346.576.798-.752.712-2.327-2.596Z"
            />
            <path
                fill="#37526C"
                d="m50.15 60.12.18 3.94c.21 4.637 5.614 7.444 9.805 5.022a9.67 9.67 0 0 0 .233-.138s1.588-.403 1.54-1.042c-.048-.638.34-.949.683-.775.344.174 4.84-5.856 4.84-5.856s9.344 4.403 11.405 5.44c0 0 1.938-2.863 1.797-2.833-.14.03-11.558-7.522-11.558-7.522s-1.86-1.779-4.168-.604c-.048.172-.007.178-.007.178l-7.622 5.87-1.077-1.933-6.051.254Z"
            />
            <path
                fill="#FF8C00"
                d="M57.336 55.197a3.845 3.845 0 0 1-.082.54c-.054.26-.143.51-.263.748 0 0 0 .266-.538 1.155-.538.89.134.508.538 1.17.403.659 0 .355-.448.78a.96.96 0 0 0-.265.445.92.92 0 0 0 .01.511s-5.776.004-6.851-.377c-1.076-.381-1.321-2.529-.357-2.741.963-.212-.292-.423-.858-.912-.566-.49.566-1.29.566-1.29s-.466-.761-.427-3.317c.005-.235.028-.47.068-.701.078-.438.194-.868.349-1.287.212-.58.464-1.146.755-1.695.156-.299.316-.584.47-.845l.07-.12a21.17 21.17 0 0 1 .953-1.452l.979-1.609 3.267 1.447-.185 2.368.563 1.785.26.822.2.634.31.982s-.085.004.015.135c.465.584.766 1.269.876 1.991.039.276.047.555.025.833Z"
            />
            <path
                fill="#FFF1CC"
                d="m66.773 55.452-.061-.019-7.821-2.38 2.882-8.934c.057-.178.156-.341.29-.478.134-.136.3-.242.483-.31a1.37 1.37 0 0 1 1.132.088l4.998 2.79c.395.221.697.566.85.975.154.41.15.856-.009 1.263l-2.744 7.005Z"
            />
            <path
                fill="#fff"
                d="m66.805 56.02-7.884-2.4 2.882-8.934c.057-.178.157-.341.29-.477.134-.137.3-.242.483-.31a1.37 1.37 0 0 1 1.132.087l5.237 2.471c.786.44.768 1.575.403 2.508l-2.543 7.054Z"
            />
            <path
                fill="#FFF1CC"
                d="M66.015 56.666a1.566 1.566 0 0 1-1.204.114l-5.04-1.534a1.538 1.538 0 0 1-.896-.717 1.399 1.399 0 0 1-.112-1.106l2.526-7.832a1.21 1.21 0 0 1 .245-.427c.11-.126.247-.23.4-.305a1.357 1.357 0 0 1 1.005-.077l5.222 1.62c.249.078.479.201.676.364.198.162.359.36.474.583a1.773 1.773 0 0 1 .083 1.438l-2.7 7.117a1.446 1.446 0 0 1-.679.762Z"
            />
            <path
                fill="#FFC5AC"
                d="M55.088 44.228c1.993 0 3.609-1.527 3.609-3.41 0-1.884-1.616-3.411-3.61-3.411-1.993 0-3.609 1.527-3.609 3.41 0 1.884 1.616 3.41 3.61 3.41Z"
            />
            <path
                fill="#273C50"
                d="M42.883 44.28a3.066 3.066 0 0 0 1.537 1.357c.265.114.543.197.828.248.081-.303.21-.592.384-.858-.058.3-.058.606 0 .906.435.034.873-.018 1.286-.15.406-.131.774-.35 1.076-.638.3-.288.527-.639.66-1.024.287-.904-.023-1.87-.047-2.813-.025-.944.449-2.073 1.44-2.19.756-.089.662-.02 1.205.484-.76.797 1.09 4.325 2.084 4.17.56-.089.974-.51 1.085-.495.568 1.315-1.142 3.18.215 3.793l-.101-.126c-.71-1.108 1.39-2.98.68-4.087-.197-.31-.403-.674-.28-1.017.116-.326.704-.429.828-.132-.202-.68.362-1.479 1.11-1.548.665-.062.627.117 1.26.28.082-.258.198-.505.348-.734a2.37 2.37 0 0 0-.019.792c.229.026.46-.02.658-.131.586-.332.603-1.188.2-1.709-.402-.52-1.077-.778-1.728-.973-.006-1.3-3.71-1.924-4.392-.714-.07-.98-1.258-1.64-2.281-1.473-1.024.166-1.831.942-2.323 1.806-.493.864-.737 1.831-1.097 2.751-.36.92-.876 1.837-1.738 2.383-.863.545-2.145.595-2.865-.112a2.065 2.065 0 0 0-.013 1.953Z"
            />
            <path
                fill="#98D5F1"
                d="M46.586 59.875a1.32 1.32 0 0 1 .242-.144c1.381-.65 5.677-2.337 9.037-2.267 6.463.133 14.688 6.873 12.774 11.4-1.456 3.444-8.698 5.404-13.984 3.836-7.406-2.195-10.33-11.121-8.069-12.825Z"
            />
            <path
                fill="#273C51"
                d="M19.755 84.689c-2.112-.377-3.982-9.368.173-13.796 1.424-1.517 3.2-2.177 5.562-2.792 13.596-3.537 23.965-.166 25.359.657 1.39.821 3.828 13.194.71 14.945-1.753.986-5.229 1.478-6.951.329-2.534-1.692 1.72-6.08-.174-9.033-2.148-3.352-13.462-4.48-19.134-1.15-6.051 3.554 6.431 12.975-5.545 10.84Z"
            />
            <path
                fill="#D4D8DC"
                d="M33.875 69.402h-8.069c-1.991-1.804-3.416-3.542-2.275-4.985 0-.415.174-.813.484-1.106.31-.293.732-.458 1.17-.458h9.31c.44 0 .86.165 1.17.458.311.293.485.691.485 1.106.434 1.644-.341 3.306-2.275 4.985Z"
            />
            <path
                fill="#FFF1CC"
                d="M32.426 60.884a.391.391 0 0 1-.217-.054.36.36 0 0 1-.145-.163.335.335 0 0 1-.02-.21.35.35 0 0 1 .11-.185l.026-.096-.01-.023a.964.964 0 0 0-.371-.422 1.046 1.046 0 0 0-1.112.004.963.963 0 0 0-.368.424c-.303.69-.688 1.38-.783 2.108-.042.322-.024.649.052.965a10.73 10.73 0 0 1-1.083-4.672 10.262 10.262 0 0 1 .235-2.193 11.114 11.114 0 0 1 2.388-4.823 3.114 3.114 0 0 0 1.333-1.307c.106-.2.18-.412.222-.632-.065.009-.244-.925-.195-.983-.09-.13-.253-.194-.351-.32-.492-.63-1.168-.52-1.522.336-.754.36-.762.956-.299 1.53.295.366.335.86.594 1.25l-.08.096c-.487.591-.91 1.225-1.266 1.894a4.52 4.52 0 0 0-.3-2.211c-.289-.657-.828-1.21-1.303-1.777-.571-.681-1.742-.384-1.842.48-.002.008-.002.017-.003.025.07.038.14.078.207.12.085.054.15.13.189.22a.455.455 0 0 1-.124.528.519.519 0 0 1-.27.124l-.01.001c.025.24.07.479.133.713-.61 2.228.707 3.04 2.586 3.076l.124.06c-.357.955-.581 1.95-.666 2.96-.049.597-.046 1.195.008 1.791l-.003-.021a2.903 2.903 0 0 0-1.058-1.692c-.815-.632-1.966-.865-2.845-1.373a.607.607 0 0 0-.626-.002.56.56 0 0 0-.215.232.523.523 0 0 0-.046.327 3.507 3.507 0 0 1 .589.294c.085.053.15.13.189.22.038.089.047.187.024.282a.473.473 0 0 1-.148.246.519.519 0 0 1-.27.123l-.01.002-.021.003c.224.505.538.97.929 1.374.38 1.945 2.018 2.13 3.77 1.563.193.79.471 1.557.832 2.292h2.967c.011-.031.02-.063.03-.094-.275.016-.55 0-.821-.047l.66-.768a.178.178 0 0 0 .014-.015l.337-.39c.006-.401-.043-.801-.145-1.19Z"
            />
            <path
                fill="#D4D8DC"
                d="M42.549 68.993c.049.136.142.253.266.335a.747.747 0 0 0 .425.123l2.96-.072a.734.734 0 0 0 .42-.141.668.668 0 0 0 .248-.35l.418-4.233c.15.063.31.095.474.092.286-.007.558-.121.755-.317a.99.99 0 0 0 .297-.729.996.996 0 0 0-.335-.713 1.115 1.115 0 0 0-.771-.28.97.97 0 0 0-.194.018.734.734 0 0 0-.248-.165.769.769 0 0 0-.297-.057l-4.553.111a.734.734 0 0 0-.39.123.69.69 0 0 0-.204.21.648.648 0 0 0-.063.553l.792 5.492Zm4.823-4.626.298-1.008a.65.65 0 0 0 .003-.357c.014 0 .027-.006.041-.006a.797.797 0 0 1 .55.202.712.712 0 0 1 .238.51.708.708 0 0 1-.212.52.793.793 0 0 1-.918.14Z"
            />
            <path
                fill="#fff"
                d="M42.302 62.629c.097.524 1.188.892 2.499.828 1.206-.052 2.192-.453 2.372-.927a.71.71 0 0 0-.206-.026l-4.553.111a.683.683 0 0 0-.112.014Z"
            />
            <path
                fill="#A9B1B9"
                d="m8.159 84.887 99.682.039a.164.164 0 0 0 .113-.044.148.148 0 0 0 .046-.106.148.148 0 0 0-.046-.106.164.164 0 0 0-.113-.044l-99.682-.038a.163.163 0 0 0-.113.044.146.146 0 0 0-.046.105c0 .04.017.078.046.106.03.028.07.044.113.044Z"
            />
            <path
                fill="#FFC5AC"
                d="M64.068 51.41a1.07 1.07 0 0 0-.715.303.956.956 0 0 0-.289.69.901.901 0 0 0 .018.154l-3.128 1.746.378 1.811 4.012-2.828c.222-.058.416-.183.555-.356a.946.946 0 0 0 .21-.6.896.896 0 0 0-.084-.359.94.94 0 0 0-.222-.3 1.042 1.042 0 0 0-.736-.261Z"
            />
            <path
                fill="#FF8C00"
                d="M61.505 55.57c-.084-.105-.12-.15-.139-.138-.049.028-.002.403-.346 1.055-.485.916-5.571 2.32-5.571 2.32l-.626-1.083-2.755-4.76-.949-3.816a1.614 1.614 0 0 1-.048-.406 17.339 17.339 0 0 1 2.686-1.461c.375.201.662.524.806.908l1.896 5.024.757 2.005s1.334-.902 1.819-.736c.483.167.62-.067.304-.815-.316-.747.159.022.71.071.553.05 0 0 .261-.317.262-.317 1.95-.496 1.95-.496l.036.071.654 1.272.188.365s-.694.712-.861.853c-.169.143-.22.133-.138.296.081.162-.344.155-.634-.211Z"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M8 15h100v70H8z" />
            </clipPath>
        </defs>
    </svg>
);

const UpcomingNextEventDashboardWidget = memo(() => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { openTab } = useEntityDetail();

    const [isDetailVisible, setIsDetailVisible] = useState(false);
    const popoverCallbacksRef = useRef({});
    const agendaPermission = useSelector(
        (state) => state?.config?.permission?.crud_permission?.[AGENDA.permission],
    );
    const nylasConfig = useSelector((state) => state.config?.nylas);
    const syncCalendarWithNylas = useSelector(
        (state) => state.config?.userData?.syncCalendarWithNylas || false,
    );
    const idUser = useSelector((state) => state?.config?.userData?.idUsuario);
    const idTimezone = useSelector((state) => state?.config?.userData?.idTimeZone);
    const userName = useSelector(
        (state) => `${state?.config?.userData?.nombre} ${state?.config?.userData?.apellidos}`,
    );

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [event, setEvent] = useState();
    const [events, setEvents] = useState();
    const [timezone, setTimezone] = useState();

    const getEvents = useCallback(() => {
        setLoading(true);
        setError(false);

        let startDate = moment().startOf('day');
        const endDate = startDate.clone();
        endDate.add(1, 'years');

        let filters = {
            fini: {
                id: 'fini',
                value: formatDateToBackendUTC(startDate.toDate()),
            },
            ffin: {
                id: 'ffin',
                value: formatDateToBackendUTC(endDate.toDate()),
            },
            taskFlag: {
                id: 'taskFlag',
                value: '2',
            },
            completed: {
                id: 'completed',
                value: '0',
            },
            useUTCDates: {
                id: 'useUTCDates',
                value: true,
            },
            idusuario: {
                value: [idUser],
                id: 'idusuario',
            },
            newAttendees: {
                id: 'newAttendees',
                value: true,
            },
        };

        Context.domainManager.getEntityList(
            AGENDA.entity,
            0,
            100,
            filters,
            '',
            '',
            '',
            false,
            (result) => {
                setLoading(false);

                let events = result.reduce((arr, item) => {
                    item = AgendaModel.standardToCrud(item);

                    if (!item.g_recurrence || item.g_recurrence === '') {
                        const startMoment = getDateToTimezone({
                            date: item.fini,
                            inputFormat: 'YYYY-MM-DD HH:mm',
                            timezone: timezone?.idianazone,
                            returnMoment: true,
                        });
                        const endMoment = getDateToTimezone({
                            date: item.hfin,
                            inputFormat: 'YYYY-MM-DD HH:mm',
                            timezone: timezone?.idianazone,
                            returnMoment: true,
                        });
                        arr.push({
                            ...item,
                            fini: startMoment.toDate(),
                            ffin: endMoment.toDate(),
                            hini: startMoment.toDate(),
                            hfin: endMoment.toDate(),
                        });
                    } else {
                        let initDateString = item.Fini;
                        let initDateFormat = 'YYYY-MM-DD';
                        if (item.Hini) {
                            initDateString = initDateString + ' ' + item.Hini;
                            initDateFormat = initDateFormat + ' ' + 'HH:mm';
                        }

                        const initDate = moment.utc(initDateString, initDateFormat).toDate();
                        let g_recurrence =
                            item.g_recurrence?.replace('RRULE:RRULE:FREQ', 'FREQ') ||
                            item.g_recurrence;
                        g_recurrence = g_recurrence?.replace('RRULE:FREQ', 'FREQ');
                        const newGRecurrence = getNewStrRuleWithOffset(g_recurrence);
                        let rule = rrulestr(newGRecurrence, {
                            dtstart: initDate,
                        });

                        let excludeDates = [];
                        if (item.g_recurrence_exception) {
                            let newGRecurrence2 = newGRecurrence;
                            newGRecurrence2 += `\n${item.g_recurrence_exception}`;
                            let rule2 = rrulestr(newGRecurrence2, {
                                dtstart: initDate,
                                forceset: true,
                                cache: false,
                            });

                            excludeDates = rule2.exdates();
                        }

                        const day0 = moment(0, 'X');

                        if (startDate && endDate) {
                            const dates = rule.between(startDate.toDate(), endDate.toDate());
                            for (let i = 0; i < dates.length; i++) {
                                if (excludeDates?.length) {
                                    const excluded = excludeDates.find(
                                        (date) => date.getTime() === dates[i]?.getTime(),
                                    );
                                    if (excluded) continue;
                                }

                                let dateYearMonthDay = moment(dates[i]).format('YYYY-MM-DD');
                                let startTime = moment(item.fini).format('HH:mm');
                                let endTime = moment(item.hfin).format('HH:mm');
                                const startMoment = getDateToTimezone({
                                    date: `${dateYearMonthDay} ${startTime}`,
                                    inputFormat: 'YYYY-MM-DD HH:mm',
                                    timezone: timezone?.idianazone,
                                    returnMoment: true,
                                });
                                const endMoment = getDateToTimezone({
                                    date: `${dateYearMonthDay} ${endTime}`,
                                    inputFormat: 'YYYY-MM-DD HH:mm',
                                    timezone: timezone?.idianazone,
                                    returnMoment: true,
                                });
                                const dateFormat = moment(dates[i]).format('DD/MM/YYYY');
                                let newDateIni;
                                let newDateFin;
                                newDateIni = moment(dateFormat, 'DD/MM/YYYY');
                                newDateFin = moment(dateFormat, 'DD/MM/YYYY');

                                if (startMoment.isAfter(moment())) {
                                    arr.push({
                                        ...item,
                                        fini: startMoment.toDate(),
                                        hini: startMoment.toDate(),
                                        ffin: endMoment.toDate(),
                                        hfin: endMoment.toDate(),
                                        Fini: newDateIni.format('YYYY-MM-DD'),
                                        Fini_timestamp: newDateIni.diff(day0, 'seconds').toString(),
                                        Ffin: newDateFin.format('YYYY-MM-DD'),
                                        Ffin_timestamp: newDateFin.diff(day0, 'seconds').toString(),
                                    });
                                }
                            }
                        }
                    }
                    return arr;
                }, []);

                events = events
                    .sort((a, b) => {
                        if (moment(a.fini).isBefore(moment(b.fini))) {
                            return -1;
                        } else if (moment(a.fini).isAfter(moment(b.fini))) {
                            return 1;
                        } else {
                            return 0;
                        }
                    })
                    .filter((event) => {
                        if (moment(event.ffin).isBefore(moment())) return false;
                        return true;
                    });

                const upcomingEvent = events.filter((event) => {
                    if (getBackendBoolean(event.isTodoDia)) return false;
                    return true;
                })[0];

                setEvent(upcomingEvent);
                setEvents(events.filter((event) => event.Id !== upcomingEvent.Id));
            },
            (error) => {
                console.error(error);
                setLoading(false);
                setError(true);
            },
        );
    }, [idUser, timezone?.idianazone]);

    useEffect(() => {
        dispatch(EntityListActions.setUseLazyLoad(AGENDA, true));
        return subscribe(`${ENTITY_MODAL_UPDATE}--${AGENDA.entity}`, () => getEvents());
    }, [dispatch, getEvents]);

    useEffect(() => {
        Context.serverListManager.getList('fm_iana_time_zone').then((data) => {
            const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const userTimezone = data.reduce((obj, current) => {
                if (current.ianazonenames?.includes(browserTimezone)) obj = current;
                return obj;
            }, {});
            if (Object.keys(userTimezone).length) {
                setTimezone(userTimezone);
            }
        });
    }, []);

    useEffect(() => {
        if (!timezone) return;
        getEvents();
    }, [getEvents, timezone]);

    const onViewMore = useCallback(() => {
        logEvent({
            event: 'dashboard',
            submodule: AGENDA.trueName,
            functionality: 'list',
        });
        window.open(getRoute(AGENDA.route), '_blank');
    }, []);

    const onAdd = useCallback(() => {
        const defaultRange = getPreviousAndNextDate(60);
        const hini = defaultRange.previous;
        const fini = new Date(hini);

        dispatch(
            EntityModalActions.init({
                entity: AGENDA,
                data: {
                    user: {
                        label: userName,
                        value: idUser,
                    },
                    fini,
                    hini,
                    idTimezone,
                    isCompletado: '0',
                    isTarea: '0',
                },
                labels: {
                    title: getLiteral('title_create_event'),
                    success: getLiteral('succes_entitycreatedsuccessfully'),
                    error: getLiteral('label_failed_insert_activity_salesforce'),
                },
                crudTab: AGENDA_EVENT,
                hideDelete: true,
            }),
        );

        logEvent({
            event: 'dashboard',
            submodule: AGENDA.trueName,
            functionality: 'create',
        });
    }, [dispatch, idTimezone, idUser, userName]);

    const onPopupVisibleChange = useCallback((isVisible) => {
        setIsDetailVisible(isVisible);
        if (popoverCallbacksRef?.current?.onPopupVisibleChange) {
            popoverCallbacksRef?.current?.onPopupVisibleChange(isVisible);
        }

        if (isVisible) {
            logEvent({
                event: 'dashboard',
                submodule: AGENDA.trueName,
                functionality: 'detailView',
            });
        }
    }, []);

    const onDeleteEvent = useCallback(() => {
        return new Promise((resolve, reject) => {
            const id = event.Id;
            Context.domainManager.deleteEntity(
                AGENDA.entity,
                id,
                () => {
                    getEvents();
                    resolve();
                },
                (error) => {
                    console.error(error);
                    reject();
                },
            );
        });
    }, [event?.Id, getEvents]);

    const onEditEvent = useCallback(
        (event) => {
            if (!event || event?.g_recurrence) return;
            const canEditItem = getBackendBoolean(event.CanEdit);
            if (!agendaPermission?.update || !(canEditItem && !event.isReadOnly)) {
                return;
            }

            dispatch(
                EntityModalActions.init({
                    entity: AGENDA,
                    id: event.Id,
                    labels: {
                        title: getLiteral('title_edit_appointment'),
                        success: getLiteral('succes_entityupdatedsuccessfully'),
                        error: getLiteral('error_generalerror'),
                        deleteModalTitle: getLiteral('title_delete_event'),
                        successDelete: getLiteral('succes_entitydeletedsuccessfully'),
                    },
                    hideDelete: !agendaPermission?.delete,
                    disableConfirm: !agendaPermission?.update,
                    crudTab: AGENDA_EVENT,
                }),
            );

            logEvent({
                event: 'dashboard',
                submodule: AGENDA.trueName,
                functionality: 'detailView',
            });
        },
        [agendaPermission?.delete, agendaPermission?.update, dispatch],
    );

    const onClickCompany = useCallback(
        (e) => {
            if (e.ctrlKey || !event) return true;

            e.stopPropagation();
            e.preventDefault();

            openTab({
                entity: COMPANIES,
                id: event.IdCompany,
                hasCrudInDetail: false,
                toTab: true,
                avoidRedirects: true,
            });
        },
        [event, openTab],
    );

    const onClickAddress = useCallback((e) => {
        logEvent({
            event: 'dashboard',
            submodule: AGENDA.trueName,
            functionality: 'route',
        });
    }, []);

    const emptyActions = useMemo(() => {
        let actions = [];

        const nylasStatus = nylasConfig?.account?.status || 0;
        if (syncCalendarWithNylas && nylasStatus <= 0) {
            actions.push(
                <Button
                    type="secondary"
                    size="small"
                    onClick={() => {
                        logEvent({
                            event: 'dashboard',
                            submodule: AGENDA.trueName,
                            functionality: 'syncEmailCal',
                        });
                        ensureRoute('/settings/nylas');
                    }}
                >
                    {getLiteral('action_sync_cal')}
                </Button>,
            );
        }

        return actions;
    }, [nylasConfig?.account?.status, syncCalendarWithNylas]);

    const attendeesList = useMemo(() => {
        return (
            <div className="fm-agenda-small-detail__attendees-content">
                <AttendeesList data={event} />
            </div>
        );
    }, [event]);

    const upcomingEvent = useMemo(() => {
        if (!event || !timezone) return null;
        const canEditItem = getBackendBoolean(event.CanEdit);
        const canDeleteItem = getBackendBoolean(event.CanDelete);

        const isEventEditable =
            !event.g_recurrence && agendaPermission?.update && canEditItem && !event.isReadOnly;

        const isEventDeletable =
            !event.g_recurrence &&
            agendaPermission?.delete &&
            canEditItem &&
            canDeleteItem &&
            !event.isReadOnly;

        let popoverCustomProps = {};
        if (isEventEditable) {
            popoverCustomProps = {
                onEdit: () => onEditEvent(event),
                titleDelete: getLiteral('title_delete_event'),
                subtitleDelete: getLiteralWithParameters('confirm_delete', [`"${event.Asunto}"`]),
            };
        } else {
            let labelCantEdit = '';

            if (!agendaPermission.update || !canEditItem || event.isReadOnly) {
                labelCantEdit = getLiteral('label_calendar_no_edit_no_permission');
            }
            if (event.g_recurrence) {
                labelCantEdit = getLiteral('label_calendar_no_edit_recurrent');
            }

            popoverCustomProps = { labelCantEdit };
        }

        if (isEventDeletable) {
            popoverCustomProps.onDelete = () => onDeleteEvent(event);
        }

        const showAddress = event.Direccion && event.lat && event.lon;
        const showCompany = event.IdCompany !== '-1';

        const startDate = getDateToTimezone({
            date: event.fini,
            timezone: timezone.idianazone,
            returnMoment: true,
        });
        const endDate = getDateToTimezone({
            date: event.ffin,
            timezone: timezone.idianazone,
            returnMoment: true,
        });

        const firstSegmentDate = startDate.isSame(moment(), 'day')
            ? getLiteral('label_today')
            : getActivityDateFormat(startDate, true, false);

        return (
            <div className="fm-dashboard-widget__container fm-upcoming-next-event-widget fm-agenda-small-detail ">
                <Text type="subtitle" color="neutral900">
                    {firstSegmentDate}, {startDate.format('HH:mm')} - {endDate.format('HH:mm')}
                </Text>
                <Text color="neutral700">{event.Asunto || '-'}</Text>
                {showCompany && (
                    <>
                        <Spacer y={5} />
                        <CompanyChip
                            idCompany={event.IdCompany}
                            remotePopover={true}
                            onClickPopover={onClickCompany}
                        >
                            <div className="fm-upcoming-next-event-widget__row">
                                <CompanyAvatar id={event.IdCompany} />
                                <div className="fm-upcoming-next-event-widget__row-right">
                                    <Text type="caption" color="neutral700">
                                        {getLiteral('label_account')}
                                    </Text>
                                    <Spacer y={1} />
                                    <Link
                                        href={getCompanyDetailUrl(event.IdCompany)}
                                        target="blank"
                                        onClick={onClickCompany}
                                    >
                                        {event.empresa}
                                    </Link>
                                </div>
                            </div>
                        </CompanyChip>
                    </>
                )}
                {showAddress && (
                    <>
                        <Spacer y={5} />
                        <div className="fm-upcoming-next-event-widget__row">
                            <Avatar icon="location" />
                            <div className="fm-upcoming-next-event-widget__row-right">
                                <Text type="caption" color="neutral700">
                                    {getLiteral('label_address')}
                                </Text>
                                <Spacer y={1} />
                                <Link
                                    href={`https://www.google.com/maps/search/?api=1&query=${event.lat},${event.lon}`}
                                    target="blank"
                                    isTruncated
                                    onClick={onClickAddress}
                                >
                                    {event.Direccion || '-'}
                                </Link>
                            </div>
                        </div>
                    </>
                )}
                {event.attendees?.length > 0 && (
                    <>
                        <Spacer y={5} />
                        <div className="fm-upcoming-next-event-widget__row">
                            <Avatar icon="multipleUsers" />
                            <div className="fm-upcoming-next-event-widget__row-right">
                                <Popover placement="bottom" content={attendeesList}>
                                    <AttendeesField
                                        data={event}
                                        useDivider={false}
                                        withIcon={false}
                                    />
                                </Popover>
                            </div>
                        </div>
                    </>
                )}
                <Spacer y={5} />
                <div className="fm-dashboard-widget__footer">
                    <EntityDetailPopover
                        {...popoverCustomProps}
                        content={
                            <AgendaSmallDetail
                                data={event}
                                popoverCallbacks={popoverCallbacksRef.current}
                                isDetailVisible={isDetailVisible}
                            />
                        }
                        onPopupVisibleChange={onPopupVisibleChange}
                        entity={AGENDA}
                        entityId={event?.Id}
                    >
                        <Button type="secondary" icon="launch">
                            {getLiteral('action_view_detail')}
                        </Button>
                    </EntityDetailPopover>
                    <VideoCallField data={event} withCopy={false} withIcon={false} />
                </div>
            </div>
        );
    }, [
        agendaPermission?.delete,
        agendaPermission.update,
        attendeesList,
        event,
        isDetailVisible,
        onClickAddress,
        onClickCompany,
        onDeleteEvent,
        onEditEvent,
        onPopupVisibleChange,
        timezone,
    ]);

    const rowList = useMemo(() => {
        return events?.map((item) => {
            const canEditItem = getBackendBoolean(item.CanEdit);
            const canDeleteItem = getBackendBoolean(item.CanDelete);

            const isEventEditable =
                !item.g_recurrence && agendaPermission?.update && canEditItem && !item.isReadOnly;

            const isEventDeletable =
                !item.g_recurrence &&
                agendaPermission?.delete &&
                canEditItem &&
                canDeleteItem &&
                !item.isReadOnly;

            let popoverCustomProps = {};
            if (isEventEditable) {
                popoverCustomProps = {
                    onEdit: () => onEditEvent(item),
                    titleDelete: getLiteral('title_delete_event'),
                    subtitleDelete: getLiteralWithParameters('confirm_delete', [
                        `"${item.Asunto}"`,
                    ]),
                };
            } else {
                let labelCantEdit = '';

                if (!agendaPermission.update || !canEditItem || item.isReadOnly) {
                    labelCantEdit = getLiteral('label_calendar_no_edit_no_permission');
                }
                if (item.g_recurrence) {
                    labelCantEdit = getLiteral('label_calendar_no_edit_recurrent');
                }

                popoverCustomProps = { labelCantEdit };
            }

            if (isEventDeletable) {
                popoverCustomProps.onDelete = () => onDeleteEvent(item);
            }

            return (
                <EntityDetailPopover
                    {...popoverCustomProps}
                    content={
                        <AgendaSmallDetail
                            data={item}
                            popoverCallbacks={popoverCallbacksRef.current}
                            isDetailVisible={isDetailVisible}
                        />
                    }
                    onPopupVisibleChange={onPopupVisibleChange}
                    entity={AGENDA}
                    entityId={item?.Id}
                >
                    <DashboardWidgetRow
                        item={item}
                        leftColumn={
                            <Icon name="event" size="large" color={theme.colors.neutral600} />
                        }
                        title={item.Asunto || '-'}
                        subtitle={getActivityDateFormat(moment(item.fini))}
                    />
                </EntityDetailPopover>
            );
        });
    }, [
        agendaPermission?.delete,
        agendaPermission?.update,
        events,
        isDetailVisible,
        onDeleteEvent,
        onEditEvent,
        onPopupVisibleChange,
        theme.colors.neutral600,
    ]);

    return (
        <DashboardWidget
            title={getLiteral('label_upcoming_event')}
            // State
            isLoading={loading}
            isEmpty={!event && !error}
            isError={error}
            // Empty view
            emptyTitle={getLiteral('label_empty_widget_events')}
            emptySubtitle={getLiteral('label_empty_widget_events_desc')}
            emptyImage={EMPTY_SVG}
            emptyActions={emptyActions}
        >
            {upcomingEvent}
            <DashboardWidgetHeader
                title={getLiteral('label_next_events')}
                onViewMore={onViewMore}
                onAdd={agendaPermission?.create ? onAdd : undefined}
                addTooltip={getLiteral('action_add_event')}
            />
            {rowList}
        </DashboardWidget>
    );
});

export default UpcomingNextEventDashboardWidget;
